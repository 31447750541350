import React from 'react'

function Cross() {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2"
        y="2"
        width="28"
        height="28"
        rx="14"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M17.6151 16.0006L21.6514 11.9631C21.7605 11.8578 21.8475 11.7318 21.9073 11.5925C21.9671 11.4532 21.9986 11.3033 22 11.1517C22.0013 11.0001 21.9724 10.8498 21.915 10.7094C21.8576 10.5691 21.7728 10.4416 21.6656 10.3344C21.5584 10.2272 21.4309 10.1424 21.2905 10.085C21.1502 10.0276 20.9999 9.99873 20.8483 10C20.6967 10.0014 20.5468 10.0329 20.4075 10.0927C20.2682 10.1525 20.1422 10.2395 20.0369 10.3486L15.9994 14.3849L11.9631 10.3486C11.8578 10.2395 11.7318 10.1525 11.5925 10.0927C11.4532 10.0329 11.3033 10.0014 11.1517 10C11.0001 9.99873 10.8498 10.0276 10.7094 10.085C10.5691 10.1424 10.4416 10.2272 10.3344 10.3344C10.2272 10.4416 10.1424 10.5691 10.085 10.7094C10.0276 10.8498 9.99873 11.0001 10 11.1517C10.0014 11.3033 10.0329 11.4532 10.0927 11.5925C10.1525 11.7318 10.2395 11.8578 10.3486 11.9631L14.3849 15.9994L10.3486 20.0369C10.2395 20.1422 10.1525 20.2682 10.0927 20.4075C10.0329 20.5468 10.0014 20.6967 10 20.8483C9.99873 20.9999 10.0276 21.1502 10.085 21.2905C10.1424 21.4309 10.2272 21.5584 10.3344 21.6656C10.4416 21.7728 10.5691 21.8576 10.7094 21.915C10.8498 21.9724 11.0001 22.0013 11.1517 22C11.3033 21.9986 11.4532 21.9671 11.5925 21.9073C11.7318 21.8475 11.8578 21.7605 11.9631 21.6514L15.9994 17.6151L20.0369 21.6514C20.1422 21.7605 20.2682 21.8475 20.4075 21.9073C20.5468 21.9671 20.6967 21.9986 20.8483 22C20.9999 22.0013 21.1502 21.9724 21.2905 21.915C21.4309 21.8576 21.5584 21.7728 21.6656 21.6656C21.7728 21.5584 21.8576 21.4309 21.915 21.2905C21.9724 21.1502 22.0013 20.9999 22 20.8483C21.9986 20.6967 21.9671 20.5468 21.9073 20.4075C21.8475 20.2682 21.7605 20.1422 21.6514 20.0369L17.6151 15.9994V16.0006Z"
        fill="#850C70"
      />
    </svg>
  )
}

export default Cross
