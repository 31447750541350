import { css, StyleSheet } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'wouter'
import {
  useApartmentPreview,
  useCameraControls,
  useGuidedTour,
  useMaster,
  useMenuButtons,
  useTimeOfDaySwitch,
  useMode,
  useMaster3bhk,
} from '../../store'
import DayLighting from '../assets/Buttons/DayLighting'
import Guide from '../assets/Buttons/Guide'
import Info from '../assets/Buttons/Info'
import Home from '../assets/Buttons/Home'
import Map from '../assets/Buttons/Map'
import MapClose from '../assets/Buttons/MapClose'
import NightLighting from '../assets/Buttons/NightLighting'
import MapComponent from './MapComponent'
import RoomInfo from './RoomInfo'
import Record from '../assets/Buttons/Record'
import TimerUI from '../assets/images/TimerUI'
import PlayButton from '../assets/images/PlayButton'
import PlayButtonImg from '../assets/images/PlayIcon.webp'

function Menu() {
  const [location, setLocation] = useLocation()
  const { setCamValues } = useCameraControls()
  const { setLighting } = useTimeOfDaySwitch()
  const [dayTime, setDayTime] = useState(true)
  const [play, setPlay] = useState(false)
  const {
    guidedTour,
    setGuidedTour,
    pauseTour,
    setPauseTour,
    setPauseState,
    pauseState,
    showSeconds,
  } = useGuidedTour()
  const { setShowGuide } = useMenuButtons()
  const [showMap, setShowMap] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const { getObject } = useMaster()
  const { getObject3bhk } = useMaster3bhk()
  const { setApartmentPreview } = useApartmentPreview()
  const [showComponent, setShowComponent] = useState(false)
  const [timerSeconds, setTimerSeconds] = useState(10)
  const [guideTour, setGuideTour] = useState(false)
  const [homeButtonState, setHomeButtonState] = useState(false)
  const [nightButtonState, setNightButtonState] = useState(false)
  const [stopButtonState, setStopButtonState] = useState(false)
  const [mapButtonState, setMapButtonState] = useState(false)
  const [infoButtonState, setInfoButtonState] = useState(false)
  const [guideButtonState, setGuideButtonState] = useState(false)
  const [playButtonState, setPlayButtonState] = useState(false)

  function HomeClick() {
    setHomeButtonState(false)
    if (location.includes('3bhk')) {
      setLocation('/3bhk/home')
    } else {
      setLocation('/2bhk/home')
    }
    setApartmentPreview(true)
    setCamValues({
      position: [],
      lookAt: [16.8999727119186, 20.60282890220478, -5.527502536840995],
    })
    if (guidedTour) {
      setGuidedTour(false)
    }
  }

  function TimeOfDaySwitch() {
    setNightButtonState(false)
    setLighting(!dayTime)
    setDayTime(!dayTime)
  }

  function guidedTourState() {
    if (!guidedTour) {
      setGuidedTour(true)
    } else {
      setPauseState(!pauseState)
      setPauseTour(!pauseTour)
    }
  }

  function OnGuideButtonClicked() {
    setShowGuide(true)
    if (guideTour) {
      setPauseTour(true)
    }
  }

  // function OnInfoButtonClicked() {
  //   setShowInfo(true)
  //   if (guideTour) {
  //     setPauseTour(true)
  //   }
  // }

  function OnStopButtonClicked() {
    setGuidedTour(false)
    stopButtonState(false)
  }

  function showMapfunc() {
    setShowMap(!showMap)
    setPauseTour(true)
  }

  useEffect(() => {
    setGuideTour(guidedTour)
  }, [])

  useEffect(() => {
    const unsub = useGuidedTour.subscribe(
      (paw, previousPaw) => {
        setPlay(paw)
        setGuideTour(paw)
      },
      (state) => state.guidedTour
    )

    const unsub1 = useGuidedTour.subscribe(
      (paw, previousPaw) => {
        setPlay(!paw)
      },
      (state) => state.pauseTour
    )

    return function cleanup() {
      unsub()
      unsub1()
    }
  })

  useEffect(() => {}, [showSeconds])

  useEffect(() => {}, [timerSeconds])

  useEffect(() => {
    if (
      location !== `/${useMode.getState().name}` &&
      location !== '/' &&
      location !== `/${useMode.getState().name}/home`
    ) {
      setTimeout(() => {
        setShowComponent(true)
      }, [1500])
    } else {
      setShowComponent(false)
    }
  }, [location])

  function HomeButtonState(bool) {
    setHomeButtonState(bool)
  }

  function NightButtonState(bool) {
    setNightButtonState(bool)
  }

  function StopButtonState(bool) {
    setStopButtonState(bool)
  }

  function MapButtonState(bool) {
    setMapButtonState(bool)
  }

  function InfoButtonState(bool) {
    setInfoButtonState(bool)
  }

  function GuideButtonState(bool) {
    setGuideButtonState(bool)
  }

  function PlayButtonState(bool) {
    setPlayButtonState(bool)
  }

  return (
    <>
      {showComponent && (
        <>
          <div className={css(styles.bottomLeftContainer)}>
            <button
              className={css(styles.homeButton)}
              onClick={HomeClick}
              onMouseEnter={() => {
                HomeButtonState(true)
              }}
              onMouseLeave={() => {
                HomeButtonState(false)
              }}
            >
              <Home opacity={homeButtonState ? 1 : 0.75} />
            </button>
            <button
              className={css(styles.nightButton)}
              onClick={TimeOfDaySwitch}
              onMouseEnter={() => {
                NightButtonState(true)
              }}
              onMouseLeave={() => {
                NightButtonState(false)
              }}
            >
              {dayTime && (
                <NightLighting opacity={nightButtonState ? 1 : 0.75} />
              )}
              {!dayTime && (
                <DayLighting opacity={nightButtonState ? 1 : 0.75} />
              )}
            </button>
            {guidedTour && (
              <button
                className={css(styles.playButton)}
                onClick={OnStopButtonClicked}
                onMouseEnter={() => {
                  StopButtonState(true)
                }}
                onMouseLeave={() => {
                  StopButtonState(false)
                }}
              >
                <Record opacity={stopButtonState ? 1 : 0.75} />
              </button>
            )}
          </div>
          {/* Map button */}
          <button
            className={css(styles.mapButtonContainer)}
            onClick={showMapfunc}
            onMouseEnter={() => {
              MapButtonState(true)
            }}
            onMouseLeave={() => {
              MapButtonState(false)
            }}
          >
            {!showMap ? (
              <Map opacity={mapButtonState ? 1 : 0.75} />
            ) : (
              <MapClose opacity={mapButtonState ? 1 : 0.75} />
            )}
          </button>

          {/* <div className={css(styles.thinText)}>T1 - 01</div> */}
          {/* <div className={css(showInfo ? styles.displayNone : styles.boldText)}>{getObject(location).title}</div> */}
          {guidedTour && (
            <div className={css(styles.AutoTourText)}>
              <div className={css(styles.boldText)}>
                {pauseTour && <div>AUTOMATIC TOUR IN PAUSED</div>}
                {!pauseTour && <div>AUTOMATIC TOUR IN PROGRESS</div>}
              </div>
            </div>
          )}

          <div className={css(styles.buttonContainer)}>
            {/* <button
              className={css(styles.guideButton, styles.btn)}
              onClick={OnInfoButtonClicked}
              onMouseEnter={() => {
                InfoButtonState(true)
              }}
              onMouseLeave={() => {
                InfoButtonState(false)
              }}
            >
              <Info opacity={infoButtonState ? 1 : 0.75} />
            </button> */}
            <button
              className={css(styles.guideButton)}
              onClick={OnGuideButtonClicked}
              onMouseEnter={() => {
                GuideButtonState(true)
              }}
              onMouseLeave={() => {
                GuideButtonState(false)
              }}
            >
              <Guide opacity={guideButtonState ? 1 : 0.75} />
            </button>
          </div>

          {/* Play Button to Start Guided Tour */}
          {/* <button
            className={css(styles.timerContainer)}
            onClick={guidedTourState}
            onMouseEnter={() => {
              PlayButtonState(true)
            }}
            onMouseLeave={() => {
              PlayButtonState(false)
            }}
          >
            <div className={css(styles.timerTextContainer)}>
              {!pauseTour && guideTour && (
                <div className={css(styles.timerText)}>{showSeconds}</div>
              )}
              {(pauseTour || !guideTour) && (
                <div className={css(styles.playButtonstyle)}>
                  <img src={PlayButtonImg} alt="Play BUtton"></img>
                </div>
              )}
            </div>
            <div className={css(styles.circle)}>
              <TimerUI opacity={playButtonState ? 1 : 0.65} />
            </div>
            <div>
              <svg className={css(styles.animatedCircle)}>
                <circle
                  r="25"
                  cx="27"
                  cy="27"
                  className={css(styles.animatedInnerCircle)}
                ></circle>
              </svg>
            </div>
          </button> */}

          {/* RoomInfo Component */}
          <RoomInfo
            title={
              location.includes('3bhk')
                ? getObject3bhk(location).title
                : getObject(location).title
            }
            showInfo={showInfo}
            setShowInfo={setShowInfo}
          />
          {/* Map Component */}
          <MapComponent showMap={showMap} setShowMap={setShowMap} />
        </>
      )}
    </>
  )
}
export default Menu

const countdown = {
  from: {
    strokeDashoffset: '0px',
  },
  to: {
    strokeDashoffset: '155px',
  },
}

const styles = StyleSheet.create({
  displayNone: {
    display: 'none',
  },
  bottomLeftContainer: {
    position: 'absolute',
    left: 24,
    bottom: 56,
    display: ' flex',
    flexWrap: 'nowrap',
    '@media(max-width:576px) and (orientation: landscape)': {
      left: 14,
      bottom: 14,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      left: 14,
      bottom: 14,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      left: 14,
      bottom: 14,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      left: 14,
      bottom: 18,
    },
    '@media(min-width: 1200px)': {
      left: 14,
      bottom: 18,
    },
    '@media(min-width: 1400px)': {
      left: 24,
      bottom: 56,
    },
    '@media(min-width: 2560px)': {
      left: 44,
      bottom: 56,
    },
  },
  homeButton: {
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    width: 48,
    height: 48,
    marginLeft: 8,
    marginRight: 8,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      marginLeft: 8,
      marginRight: 8,
    },
    '@media(min-width: 1920px)': {
      transform: 'scale(1.2)',
      marginLeft: 16,
      marginRight: 16,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      marginLeft: 28,
      marginRight: 28,
    },
  },
  nightButton: {
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    width: 48,
    height: 48,
    marginLeft: 8,
    marginRight: 8,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      marginLeft: 8,
      marginRight: 8,
    },
    '@media(min-width: 1920px)': {
      transform: 'scale(1.2)',
      marginLeft: 16,
      marginRight: 16,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      marginLeft: 28,
      marginRight: 28,
    },
  },
  playButton: {
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    width: 48,
    height: 48,
    marginLeft: 8,
    marginRight: 8,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      marginLeft: 2,
      marginRight: 2,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      marginLeft: 8,
      marginRight: 8,
    },
    '@media(min-width: 1920px)': {
      transform: 'scale(1.2)',
      marginLeft: 16,
      marginRight: 16,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      marginLeft: 28,
      marginRight: 28,
    },
  },
  mapButtonContainer: {
    position: 'absolute',
    right: 35,
    bottom: 56,
    width: 48,
    height: 48,
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 14,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 18,
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      right: 15,
      bottom: 18,
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      right: 35,
      bottom: 56,
    },
    '@media(min-width: 1920px)': {
      transform: 'scale(1.2)',
      right: 35,
      bottom: 56,
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      right: 55,
      bottom: 56,
    },
  },
  textContainer: {
    position: 'absolute',
    top: 35,
    right: 32,
    textAlign: 'right',
    backgroundColor: 'red',
    '@media(max-width:576px) and (orientation: landscape)': {
      right: 15,
      top: 8,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      right: 15,
      top: 8,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      right: 15,
      top: 8,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      right: 15,
      top: 18,
    },
    '@media(min-width: 1200px)': {
      right: 15,
      top: 18,
    },
    '@media(min-width: 1400px)': {
      top: 35,
      right: 32,
    },
    '@media(min-width: 2560px)': {
      right: 55,
    },
  },
  boldText: {
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: 16,
    lineHeight: '19px',
    fontFamily: 'DIN-Bold',
    marginTop: 8,
    '@media(max-width:576px) and (orientation: landscape)': {
      fontSize: 12,
      lineHeight: '16px',
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      fontSize: 12,
      lineHeight: '16px',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      fontSize: 12,
      lineHeight: '16px',
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width: 1400px)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width: 1920px)': {
      fontSize: 20,
      lineHeight: '23px',
      marginTop: 12,
    },
    '@media(min-width: 2560px)': {
      fontSize: 28,
      lineHeight: '35px',
      marginTop: 16,
    },
  },
  thinText: {
    fontSize: 24,
    lineHeight: '28px',
    fontFamily: 'DIN-Regular',
    color: 'white',
    '@media(max-width:576px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width: 1200px)': {
      fontSize: 16,
      lineHeight: '19.2px',
    },
    '@media(min-width: 1400px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 1920px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 2560px)': {
      fontSize: 28,
    },
  },
  AutoTourText: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 27,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'rgba(196, 196, 196, 0.5)',
    width: 252,
    height: 32,
    textAlign: 'center',
    borderRadius: 8,
    '@media(max-width:576px) and (orientation: landscape)': {
      width: 200,
      height: 32,
      top: 15,
      borderRadius: 6,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      width: 200,
      height: 32,
      top: 15,
      borderRadius: 6,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      width: 200,
      height: 32,
      top: 15,
      borderRadius: 6,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      width: 252,
      height: 32,
      top: 27,
      borderRadius: 8,
    },
    '@media(min-width: 1200px)': {
      width: 252,
      height: 32,
      borderRadius: 8,
      top: 27,
    },
    '@media(min-width: 1920px)': {
      width: 352,
      height: 42,
      borderRadius: 12,
    },
    '@media(min-width: 2560px)': {
      width: 500,
      height: 62,
      borderRadius: 16,
    },
  },
  btn: {
    marginRight: 26,
    '@media(min-width: 556px)': {
      marginRight: 5,
    },
    '@media(min-width: 992px)': {
      marginRight: 0,
    },
    '@media(min-width: 1200px)': {
      marginRight: 0,
    },
    '@media(min-width: 1400px)': {
      marginRight: 26,
    },
    '@media(min-width: 1920px)': {
      marginRight: 28,
    },
    '@media(min-width: 2560px)': {
      marginRight: 46,
    },
  },
  buttonContainer: {
    position: 'absolute',
    top: 35,
    right: 32,
    textAlign: 'right',
    // backgroundColor: 'red',
    '@media(max-width:576px) and (orientation: landscape)': {
      right: 15,
      top: 38,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      right: 15,
      top: 38,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      right: 15,
      top: 38,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      right: 15,
      top: 74,
    },
    '@media(min-width: 1200px)': {
      right: 32,
      top: 64,
    },
    '@media(min-width: 1400px)': {
      top: 74,
      right: 32,
    },
    '@media(min-width: 1920px)': {
      top: 94,
      right: 32,
    },
    '@media(min-width: 2560px)': {
      top: 114,
      right: 55,
    },
  },
  guideButton: {
    width: 48,
    height: 48,
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    pointerEvents: 'auto',
    ':hover': {
      cursor: 'pointer',
    },
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media(min-width: 1920px)': {
      transform: 'scale(1.2)',
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
    },
  },
  timerContainer: {
    position: 'absolute',
    padding: 0,
    ':hover': {
      cursor: 'pointer',
    },
    outline: 'none',
    background: 'none',
    border: 'none',
    top: 178,
    right: 32,
    textAlign: 'right',
    zIndex: 10,
    '@media(max-width:576px) and (orientation: landscape)': {
      right: 15,
      top: 68,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      right: 15,
      top: 78,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      right: 15,
      top: 78,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      right: 15,
      top: 128,
    },
    '@media(min-width: 1200px)': {
      top: 108,
      right: 32,
    },
    '@media(min-width: 1400px)': {
      top: 138,
      right: 32,
    },
    '@media(min-width: 1920px)': {
      top: 178,
      right: 32,
    },
    '@media(min-width: 2560px)': {
      top: 218,
      right: 55,
    },
  },
  timerTextContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    height: '100%',
    zIndex: 4,
  },
  timerText: {
    fontSize: 48,
    lineHeight: '48px',
    fontFamily: 'DIN-Bold',
    color: '#850C70',
    padding: 0,
    marginTop: 19.4,
  },
  playButtonstyle: {
    // fontSize: 48,
    // lineHeight: '48px',
    fontFamily: 'DIN-Bold',
    color: '#850C70',
    padding: 0,
    // marginTop: '3.2vh',
  },
  circle: {
    zIndex: 5,
    width: 48,
    height: 48,
    marginLeft: 'auto',
    marginRight: 'auto',
    // Small devices (landscape phones, 576px and up)
    '@media(max-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media(min-width: 1920px)': {
      transform: 'scale(1.2)',
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
    },
  },
  animatedCircle: {
    position: 'absolute',
    width: 55,
    height: 55,
    right: 0,
    transform: 'rotateY(-180deg) rotateZ(-90deg)',
  },
  animatedInnerCircle: {
    strokeDasharray: '155px',
    strokeDashoffset: '0px',
    strokeLinecap: 'round',
    strokeWidth: '2px',
    stroke: 'green',
    fill: 'none',
    // animation: 'countdown 10s linear infinite forwards',
    animationName: [countdown],
    animationDuration: '10s',
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
  },
})
