import React from 'react'

function Map(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        stroke="white"
        strokeOpacity="0.75"
      />
      <rect
        x="3"
        y="3"
        width="42"
        height="42"
        rx="21"
        fill="white"
        fillOpacity={props.opacity}
      />
      <path
        d="M24 13.5C21.1004 13.5 18.75 15.8584 18.75 18.7677C18.75 21.1198 22.1812 25.4068 23.4958 26.9628C23.7621 27.2781 24.2383 27.2781 24.5042 26.9628C25.8188 25.4068 29.25 21.1198 29.25 18.7677C29.25 15.8584 26.8996 13.5 24 13.5ZM24 20.5236C23.0333 20.5236 22.25 19.7377 22.25 18.7677C22.25 17.7978 23.0333 17.0118 24 17.0118C24.9667 17.0118 25.75 17.7978 25.75 18.7677C25.75 19.7377 24.9667 20.5236 24 20.5236ZM12.8383 22.5283C12.5909 22.6276 12.3788 22.799 12.2294 23.0204C12.08 23.2418 12.0001 23.503 12 23.7704V34.2356C12 34.7089 12.4763 35.0325 12.9142 34.8569L18.6667 32.2297V22.4853C18.2983 21.8172 17.9971 21.1666 17.7812 20.5446L12.8383 22.5283ZM24 28.5369C23.4138 28.5369 22.8592 28.2785 22.4788 27.8278C21.6596 26.8579 20.7883 25.7533 20 24.6204V32.2293L28 34.905V24.6208C27.2117 25.7533 26.3408 26.8583 25.5213 27.8282C25.1408 28.2785 24.5863 28.5369 24 28.5369ZM35.0858 20.2377L29.3333 22.8649V34.9054L35.1617 32.5663C35.4091 32.467 35.6213 32.2956 35.7707 32.0742C35.9201 31.8528 36 31.5916 36 31.3242V20.8589C36 20.3857 35.5238 20.0621 35.0858 20.2377Z"
        fill="#850C70"
      />
    </svg>
  )
}

export default Map
