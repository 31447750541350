/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useState } from 'react'
import { useHotspotState, useLoader, useMode } from '../../store'
import HotspotManager from '../core/HotspotManager'
import { useLocation } from 'wouter'
import TwoBHKApartmentPreview from './TwoBHKApartmentPreview'
import ThreeBHKApartmentPreview from './ThreeBHKApartmentPreview'

export default function ApartmentPreview(props) {
  const [state, setState] = useState({
    apartmentVisibility: false,
    showHotspots: false,
  })

  const { setLoadedModel } = useLoader()
  const [location, setLocation] = useLocation()

  useEffect(() => {
    const unsub = useHotspotState.subscribe(
      (paw, previousPaw) => {
        setState({ ...state, showHotspots: paw })
      },
      (state) => state.hotspotVisibility
    )

    return function cleanup() {
      unsub()
    }
  }, [props.previewScreenVisibility])

  function ModelLoaded() {
    setLoadedModel('PreviewModel')
  }

  return (
    <>
      {useMode.getState().name === '2bhk' && (
        <TwoBHKApartmentPreview
          visibility={props.previewScreenVisibility}
          modelLoaded={ModelLoaded}
        />
      )}
      {useMode.getState().name === '3bhk' && (
        <ThreeBHKApartmentPreview
          visibility={props.previewScreenVisibility}
          modelLoaded={ModelLoaded}
        />
      )}
      <HotspotManager
        visibility={
          state.showHotspots && location === `/${useMode.getState().name}/home`
        }
      />
    </>
  )
}
