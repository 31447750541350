import React, { useEffect, useRef, useState } from 'react'
import { a as three } from '@react-spring/three'
import { useGLTF } from '@react-three/drei'
import { useApartmentPreview, useMode } from '../../store'
import { useSpring } from '@react-spring/core'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'wouter'
import { scale } from 'react-animations/lib/utils'

export default function TwoBHKApartmentPreview(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/glb/dollhouse/2BHKAPreview.glb')
  const { setLottieState } = useApartmentPreview()
  const [location, setLocation] = useLocation()
  const [visibilityState, setVisibiilityState] = useState(false)

  const apartmentAnim = useSpring({
    scale: visibilityState
      ? isMobile
        ? [1.5, 1.5, 1.5]
        : [1, 1, 1]
      : [0, 0, 0],
    onRest: () => {
      if (visibilityState) {
        setLottieState(true)
      }
    },
  })

  useEffect(() => {
    props.modelLoaded()
  }, [nodes])

  useEffect(() => {
    if (props.visibility) {
      setVisibiilityState(true)
    } else {
      setVisibiilityState(false)
    }
  }, [props.visibility])

  if (location === `/${useMode.getState().name}/home`) {
    return (
      <three.group
        ref={group}
        {...props}
        dispose={null}
        scale={apartmentAnim.scale}
      >
        <mesh
          geometry={nodes['2bhkA'].geometry}
          material={materials['2BHKAPre']}
        />
      </three.group>
    )
  } else return null
}

useGLTF.preload('/glb/dollhouse/2BHKAPreview.glb')
