import React, { Suspense, useState } from 'react'
import { useTexture, PerspectiveCamera } from '@react-three/drei'
import { useEffect, useRef } from 'react'
import * as THREE from 'three'
import { useThree } from '@react-three/fiber'
import { useFrame } from '@react-three/fiber'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/three'
import {
  useMaster,
  usePanoramaViewer,
  usePanoramaLoading,
  useMode,
  useLoader,
  useEverythingLoaded,
  useCameraControls,
  useTimeOfDaySwitch,
  useHotspotState,
  useMaster3bhk,
} from '../../store'
import { springConfigs } from '../../constants'
import HotspotManager from './HotspotManager'
import { useLocation } from 'wouter'
import { isMobile } from 'react-device-detect'

export const Panorama = (props) => {
  const url = props.currentLocation
  let fadedInOnce = useRef(false)
  const skybox = useTexture(url)
  const mesh = useRef()
  const group = useRef()
  const { reportDone } = useLoader()
  const panoramaLoadingState = usePanoramaLoading()
  const { camera, gl, scene } = useThree()
  const canvas = gl.domElement
  const [showHotspots, setShowHotspots] = useState(true)

  useEffect(() => {
    const unsub = useHotspotState.subscribe(
      (paw, previousPaw) => {
        setShowHotspots(paw)
      },
      (state) => state.hotspotVisibility
    )

    return function cleanup() {
      unsub()
    }
  }, [])

  useEffect(() => {
    // skybox.encoding = THREE.sRGBEncoding
    mesh.current.geometry.scale(-1, 1, 1)
  }, [])

  useEffect(() => {
    reportDone(props.currentLocation)
    fadedInOnce.current = true
  }, [props.currentLocation])

  useFrame((state) => {
    // raycaster.setFromCamera(camera,state.mouse)
  })

  const fadeOut = useSpring({
    from: { opacity: 1 },
    config: springConfigs.precision,
    opacity: 0,
    reset: true,
    onRest: () => props.exit && props.setLastLocationToCurrent(),
  })

  const fadeIn = useSpring({
    from: { opacity: 0 },
    config: springConfigs.precision,
    opacity: 1,
    reset: true,
  })
  return (
    <group ref={group}>
      <a.mesh rotation={[0, 0, 0]} ref={mesh}>
        <a.sphereBufferGeometry attach="geometry" args={[300, 60, 40]} />
        <a.meshBasicMaterial
          transparent
          opacity={
            props.exit
              ? fadeOut.opacity.to((x) => x)
              : props.shouldAnimate &&
                props.currentLocation !== props.lastLocation
              ? fadeIn.opacity.to((x) => x)
              : 1
          }
          map={skybox}
        />
      </a.mesh>
      {!props.exit && (
        <HotspotManager
          visibility={
            useEverythingLoaded.getState().agreementAccepted && showHotspots
          }
        />
      )}
    </group>
  )
}

function PanoramaViewer(props) {
  const { everythingLoaded } = useEverythingLoaded()
  const { getObject } = useMaster()
  const { getObject3bhk } = useMaster3bhk()
  const [currentObject, setObject] = useState({ url: null })
  const locationsState = usePanoramaViewer()
  const [location] = useLocation()
  const setLastLocationToCurrent = () => {
    locationsState.setLastLocation(locationsState.currentLocation)
  }
  const loader = useLoader()
  const { setCamValues } = useCameraControls()
  const { lighting } = useTimeOfDaySwitch()
  const [state, setState] = useState({
    previous: null,
    next: null,
  })

  useEffect(() => {
    setState({ ...state, next: location })
  }, [])

  useEffect(() => {
    if (state.next !== null) {
      setState({
        ...state,
        previous: state.next,
        next: location,
      })
    }
    if (
      everythingLoaded &&
      props.argument != `/${useMode.getState().name}` &&
      props.argument != `/${useMode.getState().name}/home`
    ) {
      if (lighting) {
        {
          if (isMobile) {
            locationsState.setCurrentLocation(
              props.argument.includes('3bhk')
                ? getObject3bhk(props.argument).mobileUrl
                : getObject(props.argument).mobileUrl
            )
          } else {
            locationsState.setCurrentLocation(
              props.argument.includes('3bhk')
                ? getObject3bhk(props.argument).url
                : getObject(props.argument).url
            )
          }
        }
      } else {
        if (isMobile) {
          locationsState.setCurrentLocation(
            props.argument.includes('3bhk')
              ? getObject3bhk(props.argument).mobileNightUrl
              : getObject(props.argument).mobileNightUrl
          )
        } else {
          locationsState.setCurrentLocation(
            props.argument.includes('3bhk')
              ? getObject3bhk(props.argument).nightUrl
              : getObject(props.argument).nightUrl
          )
        }
      }
    }
  }, [lighting, props.argument])

  useEffect(() => {
    if (
      everythingLoaded &&
      props.argument != '/' &&
      props.argument !== `/${useMode.getState().name}` &&
      props.argument != `/${useMode.getState().name}/home`
    ) {
      if (props.argument.includes('3bhk'))
        setObject(getObject3bhk(props.argument))
      else {
        setObject(getObject(props.argument))
      }
      setCamValues({
        position: props.argument.includes('3bhk')
          ? getObject3bhk(props.argument).camera.position
          : getObject(props.argument).camera.position,
        lookAt: props.argument.includes('3bhk')
          ? getObject3bhk(props.argument).camera.lookAt
          : getObject(props.argument).camera.lookAt,
      })
    }
  }, [props.argument])

  if (
    props.argument != '/' &&
    props.argument !== `/${useMode.getState().name}` &&
    props.argument != `/${useMode.getState().name}/home`
  ) {
    return (
      <group>
        <Suspense fallback={null}>
          {locationsState.lastLocation &&
            locationsState.lastLocation !== locationsState.currentLocation &&
            loader.items.length === 0 &&
            state.previous != `/${useMode.getState().name}/home` && (
              <>
                <Panorama
                  setLastLocationToCurrent={setLastLocationToCurrent}
                  {...props}
                  exit
                  currentLocation={locationsState.lastLocation}
                />
              </>
            )}
          <Panorama
            shouldAnimate={locationsState.lastLocation}
            exit={false}
            currentLocation={locationsState.currentLocation}
            lastLocation={locationsState.lastLocation}
          />
        </Suspense>
      </group>
    )
  } else return null
}

export default PanoramaViewer
