import React from 'react'
import { css, StyleSheet } from 'aphrodite'
import Cross from '../assets/Buttons/Cross'
import { useSpring } from '@react-spring/core'
import { a as animated } from '@react-spring/web'

function RoomInfo(props) {
  const fadeinAnim = useSpring({
    opacity: props.showInfo ? 1 : 0,
    transform: props.showInfo ? 'scaleX(1)' : 'scaleX(0)',
    config: {
      duration: 300,
    },
  })
  return (
    <>
      <div
        className={css(styles.boldText)}
        style={{ zIndex: props.showInfo ? 1 : 0 }}
      >
        {props.title}
      </div>
      {/* <animated.div className={css(styles.container)} style={fadeinAnim}>
        <div className={css(styles.closeButtonContainer)}>
          <button
            className={css(styles.button)}
            onClick={() => {
              props.setShowInfo(false)
            }}
          >
            <Cross />
          </button>
        </div>

        {/* <div className={css(styles.desc)}>
          Lorem ipsum dolor sit amet. Quo rerum natus est amet placeat aut
          internos quia non illum perferendis. Aut aperiam illo ex voluptates
          quos ut debitis dolore eos error accusantium sit fugit aut voluptatum
          iusto et expedita quaerat. Cum fugit aspernatur non deleniti tempore
          vel officiis nisi sit quaerat itaque a doloribus similique. Eum cumque
          earum quo dignissimos eligendi est illum magnam ad officiis doloremque
          est sapiente recusandae ad consequatur magnam quo quam.
          <br /> <br />
          Et quia tempore nesciunt tenetur sed voluptatem rerum rem iste sint.
          Ut quidem exercitationem.
        </div>
      </animated.div> */}
    </>
  )
}

export default RoomInfo

const styles = StyleSheet.create({
  displayNone: {
    display: 'none',
  },
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    height: '100%',
    width: '28%',
    zIndex: 11,
    pointerEvents: 'none',
    transformOrigin: '100% 50%',
    backgroundColor: 'rgba(133, 12, 112, 0.75)',
    '@media(min-width: 556px) and (orientation: portrait)': {
      display: 'none',
    },
    '@media(min-width: 556px) and (orientation: landscape)': {
      width: '34%',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      width: '30%',
    },
    '@media(min-width: 992px)': {
      width: '34%',
    },
    '@media(min-width: 1200px)': {
      width: '28%',
    },
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 14,
    marginLeft: 15,
    '@media(min-width: 556px) and (orientation: landscape)': {
      marginTop: 9,
      marginLeft: 0,
      float: 'left',
    },
    '@media(min-width: 992px)': {
      marginTop: 14,
      marginLeft: 0,
      float: 'none',
    },
    '@media(min-width: 1400px)': {
      marginTop: 22,
      marginLeft: 15,
    },
    '@media(min-width: 2560px)': {
      marginTop: 34,
      marginLeft: 35,
    },
  },
  button: {
    outline: 0,
    background: 'none',
    border: 0,
    height: 38,
    width: 38,
    pointerEvents: 'auto',
    textAlign: 'center',
    ':hover': {
      cursor: 'pointer',
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media(min-width: 1920px)': {
      transform: 'scale(1.2)',
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
    },
  },
  boldText: {
    position: 'absolute',
    top: 35,
    right: 32,
    fontSize: 32,
    // zIndex: 2,
    zIndex: 12,
    lineHeight: '38px',
    fontFamily: 'DIN-Bold',
    color: 'rgba(255, 255, 255, 0.9)',
    '@media(min-width:556px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19px',
      right: 18,
      top: 18,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      fontSize: 16,
      lineHeight: '19px',
      right: 18,
      top: 18,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      fontSize: 24,
      right: 20,
      top: 45,
    },
    '@media(min-width: 1200px)': {
      fontSize: 24,
      top: 35,
      right: 32,
    },
    '@media(min-width: 1400px)': {
      fontSize: 32,
      top: 35,
    },
    '@media(min-width: 1920px)': {
      fontSize: 36,
      lineHeight: '42px',
      top: 35,
    },
    '@media(min-width: 2560px)': {
      fontSize: 42,
      lineHeight: '50px',
      right: 45,
    },
  },
  desc: {
    float: 'right',
    marginRight: 31,
    marginTop: 43,
    fontSize: 24,
    width: '90%',
    lineHeight: '28.13px',
    color: '#FFFFFF',
    fontFamily: 'DIN-Regular',
    textAlign: 'right',
    '@media(min-width:556px) and (orientation: landscape)': {
      fontSize: 12,
      lineHeight: '14px',
      marginTop: 10,
      // width: 190,
      marginRight: 18,
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      fontSize: 12,
      lineHeight: '14px',
      marginTop: 10,
      // width: 190,
      marginRight: 18,
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      fontSize: 18,
      lineHeight: '21px',
      // width: 300,
      marginTop: 33,
    },
    '@media(min-width: 1200px)': {
      fontSize: 18,
      lineHeight: '21px',
      // width: 300,
      marginTop: 33,
    },
    '@media(min-width: 1400px)': {
      fontSize: 24,
      lineHeight: '28.13px',
      // width: 401,
    },
    '@media(min-width: 1920px)': {
      fontSize: 28,
      lineHeight: '32.13px',
      // width: 450,
    },
    '@media(min-width: 2560px)': {
      fontSize: 38,
      lineHeight: '46px',
      // width: 600,
      marginRight: 51,
    },
  },
})
