import React, { useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { panoramas2bhk, panoramas3bhk } from '../../constants'
import { useGuidedTour, useMode } from '../../store'
import { useTimer } from 'react-timer-hook'
import { Html } from '@react-three/drei'

function GuidedTour() {
  const time = new Date()
  time.setSeconds(time.getSeconds() + 6)
  return <Timer expiryTimestamp={time} />
}

function Timer({ expiryTimestamp }) {
  const { seconds, start, pause, resume, restart } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: () => {
      // console.log('OnExpire Called')
      NextRenderCall()
    },
  })
  const [count, setCount] = useState(1)
  const [location, setLocation] = useLocation()
  const [tour, setTour] = useState(false)
  const [currentMode, setCurrentMode] = useState(panoramas2bhk)
  const { setGuidedTour, setTourCompleted, setShowSeconds, pauseTour } =
    useGuidedTour()

  useEffect(() => {
    // console.log('UseEffect Mode')
    if (useMode.getState().name === '3bhk') {
      setCurrentMode(panoramas3bhk)
    } else {
      setCurrentMode(panoramas2bhk)
    }
  }, [useMode])

  useEffect(() => {
    const unsub = useGuidedTour.subscribe(
      (paw, previousPaw) => {
        TourState(paw)
      },
      (state) => state.guidedTour
    )

    return function cleanup() {
      unsub()
    }
  }, [])

  useEffect(() => {
    if (tour) {
      PauseState(pauseTour)
    }
  }, [pauseTour])

  function TourState(paw) {
    if (paw) {
      setTour(true)
      start()
      setLocation(currentMode[0])
    } else {
      setTour(false)
      setCount(1)
      const time = new Date()
      time.setSeconds(time.getSeconds() + 6)
      expiryTimestamp = time
    }
  }

  function PauseState(paw) {
    if (paw) {
      pause()
    } else {
      resume()
    }
  }

  function NextRenderCall() {
    if (count < currentMode.length) {
      setLocation(currentMode[count])
      setCount(count + 1)
      setTimeout(() => {
        Restart()
      }, 1000)
    } else {
      setGuidedTour(false)
      setTourCompleted(true)
      setCount(1)
      setTour(false)
    }
  }

  const Restart = () => {
    const time = new Date()
    time.setSeconds(time.getSeconds() + 4)
    restart(time)
  }

  useEffect(() => {
    setShowSeconds(seconds)
  }, [seconds])

  return null
}

export default GuidedTour
