import React from 'react'

function Record(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        stroke="white"
        strokeOpacity="0.75"
      />
      <rect
        x="3"
        y="3"
        width="42"
        height="42"
        rx="21"
        fill="white"
        fillOpacity={props.opacity}
      />
      <rect x="14" y="14" width="20" height="20" rx="3" fill="#850C70" />
    </svg>
  )
}

export default Record
