import { Html } from '@react-three/drei'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { useTutorial1 } from '../../store'
import HandIconImg from '../assets/images/HandIconImg.webp'

let interval = null

function UserPrompts1() {
  const [count1, setCount1] = useState(0)
  const { setCount } = useTutorial1()

  useEffect(() => {
    if (count1 < 4) {
      if (interval !== null) {
        clearInterval(interval)
      }
      interval = setInterval(() => {
        setCount()
      }, 5000)
    } else {
      clearInterval(interval)
      interval = null
    }
  }, [count1])

  useEffect(() => {
    setCount1(useTutorial1.getState().count)
    const unsub = useTutorial1.subscribe(
      (paw, previousPaw) => {
        setCount1(paw)
      },
      (state) => state.count
    )

    return function cleanup() {
      unsub()
    }
  }, [])

  return (
    <>
      <group position={[0, 0, 0]}>
        {
          <Html
            className={css(count1 === 0 ? styles.show : styles.displayNone)}
          >
            <div className={css(styles.dragContainer)}>
              <div className={css(styles.userPromptContainer)}>
                <div className={css(styles.TextContainer)}>
                  <div className={css(styles.dragText)}>
                    Click and drag the screen to look around.
                  </div>
                </div>
              </div>
              <div className={css(styles.dragContainer1)}>
                <div className={css(styles.handAnimation)}>
                  <img
                    alt="Rotate the screen"
                    src={HandIconImg}
                    className={css(styles.lottieSize1)}
                  />
                </div>
              </div>
            </div>
          </Html>
        }
      </group>
    </>
  )
}

export default UserPrompts1

const horizontalMovement = {
  from: {
    marginLeft: -100,
  },
  to: {
    marginLeft: 100,
  },
}

const styles = StyleSheet.create({
  dragContainer: {
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 992.98px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1920px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 2560px)': {
      transform: 'scale(2)',
    },
  },
  lottieSize: {
    width: 200,
    height: 100,
  },
  userPromptContainer: {
    width: 210,
    height: 60,
    marginLeft: 150,
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    textAlign: 'center',
  },
  TextContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    paddingTop: 10,
    width: '95%',
    height: '100%',
  },
  dragText: {
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'left',
  },
  dragContainer1: {
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:portrait)': {
      display: 'none',
    },
  },
  lottieSize1: {
    width: 512 / 8,
    height: 783 / 8,
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1920px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 2560px)': {
      transform: 'scale(1.8)',
    },
  },
  handAnimation: {
    animationName: [horizontalMovement],
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
  },
  show: {
    display: 'block',
  },
  displayNone: {
    display: 'none',
    pointerEvents: 'none',
  },
  // dragContainer1:{
  //     textAlign:'center',
  //     width:242,
  //     height:82,
  // },
  // userPromptContainer1:{
  //     backgroundColor:'#ffffff',
  //     borderRadius:'4px',
  //     textAlign:'center',
  //     height:'100%',
  // },
  // TextContainer1:{
  //     marginLeft:'auto',
  //     marginRight:'auto',
  //     textAlign:'center',
  //     width:'95%',
  //     paddingTop:15
  // },
})
