import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { LoadingScreenText } from '../../constants'
import BGAgreement from '../assets/images/BGAgreement.webp'
import { print } from '../helpers/ConsoleStatements'
import LoadingScreenBranding from './LoadingScreenBranding'
import { fadeIn, fadeOut } from 'react-animations'
import {
  useApartmentPreview,
  useEverythingLoaded,
  useHotspotState,
} from '../../store'
import { useLocation } from 'wouter'
import BirlaEstateLogo from '../assets/logo/BirlaEstatesLogo.webp'

export default function AgreementScreen(props) {
  const [state, setState] = useState({
    showAgreement: false,
    isFadeOut: false,
  })
  const [location, setLocation] = useLocation()
  const { setApartmentPreview } = useApartmentPreview()
  const { setAgreementAccepted } = useEverythingLoaded()
  const { setHotspotVisibility } = useHotspotState()

  function agreeemntAccepted() {
    if (location === '/' || location === '/2bhk') {
      setLocation('/2bhk/home')
    } else if (location === '/3bhk') {
      setLocation('/3bhk/home')
    }

    setState({
      ...state,
      isFadeOut: true,
    })
    setAgreementAccepted(true)
    setApartmentPreview(true)
    setHotspotVisibility(true)
  }

  function fadeOutOver() {
    setState({
      ...state,
      showAgreement: false,
    })
  }

  useEffect(() => {
    if (props.animDone) setState({ ...state, showAgreement: true })
  }, [props.animDone])

  return (
    <div
      className={css(
        state.showAgreement ? styles.container : styles.displayNone,
        state.isFadeOut ? styles.screenfadeOut : null
      )}
      onAnimationEnd={state.isFadeOut ? fadeOutOver : null}
    >
      <div>
        <img
          src={BirlaEstateLogo}
          alt="logo"
          className={css(styles.logoSize)}
        ></img>
      </div>
      <div className={css(styles.agreementText)} style={{ opacity: 0 }}>
        {' '}
        {LoadingScreenText.agreementtext}{' '}
        <a
          href="https://rera.karnataka.gov.in/"
          target="_blank"
          rel="noreferrer"
          className={css(styles.textColor)}
        >
          https://rera.karnataka.gov.in/
        </a>
      </div>
      <div className={css(styles.buttonContainer)} style={{ opacity: 0 }}>
        <button
          className={css(styles.button)}
          onClick={() => {
            agreeemntAccepted()
          }}
        >
          <div className={css(styles.buttonText)}>
            {LoadingScreenText.accept}
          </div>
        </button>
      </div>
    </div>
  )
}

const styles = StyleSheet.create({
  textColor: {
    color: 'white',
    fontWeight: 'bold',
  },
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundImage: `url(${BGAgreement})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    textAlign: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  logoSize: {
    width: 100,
    height: 'auto',
    marginTop: 40,
    '@media (min-width: 320px) and (orientation:landscape)': {
      marginTop: 50,
    },
    '@media (min-width: 576px) and (orientation:landscape)': {
      marginTop: 20,
      width: 84,
      height: 'auto',
    },

    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': {
      marginTop: 15,
      width: 84,
      height: 'auto',
    },

    // Large devices (desktops, 992px and up)
    '@media (min-width: 992px) and (orientation:landscape)': {
      marginTop: 30,
      width: 200,
      height: 'auto',
    },

    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      width: 200,
      height: 'auto',
      marginTop: 40,
    },

    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      width: 256,
      height: 346.75,
      marginTop: 40,
    },
    '@media (min-width: 1600px)': {
      width: 256,
      height: 346.75,
      marginTop: 60,
    },
    '@media (min-width: 1920px)': {
      width: 356,
      height: 446.75,
    },
    '@media(min-width:2560px)': {
      width: 456,
      height: 546.75,
    },
  },
  agreementText: {
    animationName: {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '-moz-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Firefox */,
    '-webkit-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Safari and Chrome */,
    '-o-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Opera */,

    animationFillMode: 'forwards',
    '-webkit-animation-fill-mode': 'forwards',
    animationDuration: '1s',
    '-moz-animation-duration': '1s' /* Firefox */,
    '-webkit-animation-duration': '1s' /* Safari and Chrome */,
    '-o-animation-duration': '1s' /* Opera */,
    animationDelay: '1.5s',
    '-webkit-animation-delay': '1.5s' /* Safari and Chrome */,
    color: `#FFFFFF`,
    marginTop: 80,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media(min-width:556px) and (orientation:landscape)': {
      marginTop: 23,
      width: 342,
      fontSize: 12,
      lineHeight: '14px',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      marginTop: 7,
      width: 342,
      fontSize: 12,
      lineHeight: '14px',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      marginTop: 40,
      width: 452,
      fontSize: 20,
      lineHeight: '28px',
    },
    '@media(min-width: 1200px)': {
      marginTop: 80,
      fontSize: 28,
      lineHeight: '34px',
      width: 650,
    },
    '@media(min-width: 1400px)': {
      marginTop: 20,
      fontSize: 24,
      lineHeight: '28.13px',
      width: 800,
    },
    '@media(min-width: 1600px)': {
      marginTop: 80,
      fontSize: 32,
      lineHeight: '37.5px',
      width: 755,
    },
    '@media(min-width: 2560px)': {
      marginTop: 80,
      fontSize: 36,
      lineHeight: '47.5px',
      width: 795,
    },
  },
  buttonContainer: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    bottom: 68,
    animationName: {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '-moz-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Firefox */,
    '-webkit-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Safari and Chrome */,
    '-o-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Opera */,

    animationFillMode: 'forwards',
    '-webkit-animation-fill-mode': 'forwards',
    animationDuration: '1s',
    '-moz-animation-duration': '1s' /* Firefox */,
    '-webkit-animation-duration': '1s' /* Safari and Chrome */,
    '-o-animation-duration': '1s' /* Opera */,
    animationDelay: '1.5s',
    '-webkit-animation-delay': '1.5s' /* Safari and Chrome */,
    '@media(min-width:556px) and (orientation:landscape)': {
      bottom: 19,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      bottom: 23,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      bottom: 68,
    },
    '@media(min-width: 1200px)': {
      bottom: 68,
    },
    '@media(min-width: 1400px)': {
      bottom: 80,
    },
    '@media(min-width: 2560px)': {
      bottom: 158,
    },
  },
  button: {
    backgroundColor: '#FFFFFFBF',
    //50% - 80 //90% - EC
    border: '1px #FFFFFF',
    marginLeft: 'auto',
    marginRight: 'auto',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#FFFFFFEC',
    },
    '@media(min-width:556px) and (orientation:landscape)': {
      height: 31,
      width: 173,
      border: '1px #FFFFFF',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      height: 31,
      width: 173,
      border: '1px #FFFFFF',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      border: '1px #FFFFFF',
      width: 237,
      height: 58,
    },
    '@media(min-width: 1200px)': {
      border: '1px #FFFFFF',
      width: 307,
      height: 68,
    },
    '@media(min-width: 1400px)': {
      border: '1px #FFFFFF',
      width: 240,
      height: 53.16,
    },
    '@media(min-width: 1600px)': {
      width: 307,
      height: 68,
      border: '1px solid #FFFFFF',
    },
    '@media(min-width: 2560px)': {
      width: 407,
      height: 78,
      border: '1px solid #FFFFFF',
    },
  },
  buttonText: {
    color: '#850C70',
    fontFamily: 'DIN-Regular',
    fontSize: 12,
    lineHeight: '28px',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '28px',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 18,
      lineHeight: '28px',
    },
    '@media(min-width: 1200px)': {
      fontSize: 22,
      lineHeight: '28px',
    },
    '@media(min-width: 1400px)': {
      fontSize: 16,
      lineHeight: '18.75px',
    },
    '@media(min-width: 1600px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 2560px)': {
      fontSize: 28,
      lineHeight: '28px',
    },
  },
  displayNone: {
    display: 'none',
    pointerEvents: 'none',
  },
  screenfadeIn: {
    animationName: [fadeIn],
    animationDuration: '1s',
  },
  screenfadeOut: {
    animationName: [fadeOut],
    animationDuration: '0.5s',
    '-moz-animation-duration': '0.5s' /* Firefox */,
    '-webkit-animation-duration': '0.5s' /* Safari and Chrome */,
    '-o-animation-duration': '0.5s' /* Opera */,
    // animationDelay:'1s',
    // '-webkit-animation-delay':'1s', /* Safari and Chrome */
  },
})
