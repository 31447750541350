import React, { useState, useEffect } from 'react'
import { useTweenFinish } from '../../store'
import Tween from './Tween'

export function TweenManager(props) {
  const [inputState, setInputState] = useState(true)
  const { setTweenState } = useTweenFinish()

  const onTweenStartHelperFunctions = () => {
    setInputState(false)
  }

  const onTweenFinishHelperFunctions = () => {
    setInputState(true)
  }

  const onTweenFinish = (name) => {
    setTweenState(false)
    onTweenFinishHelperFunctions()
  }

  const onTweenStart = (name) => {
    setTweenState(true)
    onTweenStartHelperFunctions()
  }

  useEffect(() => {
    props.controls.current.enabled = inputState
  }, [inputState])

  return (
    <>
      {props.tweenPosition != null && (
        <group>
          <Tween
            tweenPoint={props.tweenPosition}
            onTweenFinish={onTweenFinish}
            onTweenStart={onTweenStart}
          />
        </group>
      )}
    </>
  )
}
