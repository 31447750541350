import React from 'react'

function Guide(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        stroke="white"
        strokeOpacity="0.75"
      />
      <rect
        x="3"
        y="3"
        width="42"
        height="42"
        rx="21"
        fill="white"
        fillOpacity={props.opacity}
      />
      <path
        d="M22.1416 27.168H25.7656C25.7656 24.624 29.7976 24.192 29.7976 20.352C29.7976 17.52 27.3016 15.912 23.7016 15.912C20.4616 15.912 18.2056 17.16 16.9096 19.128L19.8136 20.832C20.5816 19.728 21.7576 19.056 23.2936 19.056C24.8536 19.056 25.8856 19.8 25.8856 20.976C25.8856 23.064 22.1416 23.808 22.1416 27.168ZM23.9656 33.192C25.3096 33.192 26.2696 32.208 26.2696 31.008C26.2696 29.784 25.3096 28.872 23.9656 28.872C22.6216 28.872 21.6616 29.784 21.6616 31.008C21.6616 32.208 22.6216 33.192 23.9656 33.192Z"
        fill="#850C70"
      />
    </svg>
  )
}

export default Guide
