import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { fadeOut } from 'react-animations'
import { fadeIn } from 'react-animations'
import { useLocation } from 'wouter'
import { useTweenFinish } from '../../store'

export default function CameraLoadingScreen() {
  const [location] = useLocation()
  const [fadeInState, setFadeInState] = useState(false)
  const [fadeOutState, setFadeOutState] = useState(false)

  useEffect(() => {
    setFadeInState(true)
  }, [location])

  useEffect(() => {
    const unsub = useTweenFinish.subscribe(
      (paw, previousPaw) => {
        if (!paw) {
          setFadeOutState(true)
        } else {
          setFadeOutState(false)
        }
      },
      (state) => state.tweenState
    )
  })

  return (
    <div
      className={css(
        fadeInState
          ? styles.cameraLoadingScreenContainer
          : fadeOutState
          ? styles.cameraLoadingScreenFadeOut
          : null
      )}
    ></div>
  )
}

const styles = StyleSheet.create({
  cameraLoadingScreenContainer: {
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'green',
    animationName: [fadeIn],
    animationDuration: '1s',
  },
  cameraLoadingScreenFadeOut: {
    animationName: [fadeOut],
    animationDuration: '1s',
  },
})
