import React, { useRef, useEffect, useState } from 'react'
import { useThree } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { TweenManager } from './TweenManager'
import { useCameraControls, useCamRef, useGuidedTour } from '../../store'
import { useLocation } from 'wouter'

/*
CameraController
adds Orbit Controls and some customizations to the camera, sets the controls when requested via useCameraControls
*/

export function CameraController(props) {
  const {
    camera,
    gl: { domElement },
  } = useThree()
  const [loaded, setloaded] = useState(false)
  const controls = useRef()
  const { setControlRef, setCameraRef } = useCamRef()
  const [newPos, setNewPos] = useState(null)
  const { setPauseTour, infoPopup, pauseState } = useGuidedTour()
  const [clampState, setClampState] = useState({
    maxAzimuth: Infinity,
    minAzimuth: Infinity,
    minAngle: 0,
    maxAngle: Infinity,
  })
  const [location, setLocation] = useLocation()
  const [rotateSpeed, setRotateSpeed] = useState(0.3)

  function onControlsUpdate(state) {
    setNewPos([state[0].lookAt[0], state[0].lookAt[1], state[0].lookAt[2]])
  }

  useEffect(() => {
    const unsubscribeControls = useCameraControls.subscribe(
      (paw, previousPaw) => onControlsUpdate(paw),
      (state) => state.cameraValues
    )
    if (!loaded) {
      setloaded(true)
      camera.fov = 50
      camera.position.set(
        16.8999727119186,
        20.60282890220478,
        -5.527502536840995
      )
      camera.updateProjectionMatrix()
      setControlRef(controls)
      setCameraRef(camera)
    }

    return function cleanup() {
      unsubscribeControls()
    }
  }, [loaded, camera])

  useEffect(() => {
    controls.current.addEventListener('start', onControlStart, false)
    // controls.current.addEventListener('end', onControlEnd, false)

    return function cleanup() {
      controls.current.removeEventListener('start', onControlStart, false)
      // controls.current.removeEventListener('end', onControlEnd, false)
    }
  })

  useEffect(() => {
    if (location.includes('/home')) {
      setClampState({
        ...clampState,
        minAngle: Math.PI / 12,
        maxAngle: Math.PI / 2.5,
      })
      setRotateSpeed(0.3)
    } else {
      setClampState({
        ...clampState,
        minAngle: Math.PI / 4,
        maxAngle: Infinity,
      })
      setRotateSpeed(-0.3)
    }
  }, [location])

  function onControlStart() {
    console.log('OnControlStart')
    if (useGuidedTour.getState().guidedTour) {
      setPauseTour(true)
    }
  }

  // function onControlEnd() {
  //   if (!infoPopup && !pauseState) {
  //     setPauseTour(false)
  //   }
  // }

  return (
    <>
      <OrbitControls
        regress
        ref={controls}
        enableZoom={false}
        enablePan={false}
        enableDamping={props.dampingState}
        rotateSpeed={rotateSpeed}
        maxAzimuthAngle={clampState.maxAzimuth}
        minAzimuthAngle={clampState.minAzimuth}
        minPolarAngle={clampState.minAngle}
        maxPolarAngle={clampState.maxAngle}
      />
      <TweenManager controls={controls} tweenPosition={newPos} />
    </>
  )
}
