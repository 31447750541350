import { css, StyleSheet } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import { useTutorial } from '../../store'
import UserPromptContainer from '../assets/images/UserPromptContainer'
import UserPromptContainerFlipped from '../assets/images/UserPromptContainerFlipped'
import { isMobile } from 'react-device-detect'
import HandIconImg from '../assets/images/HandIconImg.webp'
import { Timer } from './Timer'

export default function UserPrompts2D() {
  const { showOnce, setShowOnce, setShowHint } = useTutorial()
  const [hintState, setHintState] = useState(false)

  useEffect(() => {
    setShowHint(true)
    setHintState(true)
    setShowOnce(false)
    Timer()
  }, [])

  useEffect(() => {
    const unsub = useTutorial.subscribe(
      (paw, previousPaw) => {
        setHintState(paw)
      },
      (state) => state.showHint
    )

    return function cleaup() {
      unsub()
    }
  }, [])

  if (hintState) {
    return (
      <>
        <div className={css(styles.automatedPromptContainer)}>
          {!isMobile ? <UserPromptContainer /> : <UserPromptContainerFlipped />}
          <div className={css(styles.TextContainer2)}>
            <div className={css(styles.dragText)}>
              Click to begin an automated walkthrough of the apartment.
            </div>
          </div>
        </div>
        <div className={css(styles.dragContainer1)}>
          <div className={css(styles.handAnimation)}>
            <img
              alt="Rotate the screen"
              src={HandIconImg}
              className={css(styles.lottieSize1)}
            />
          </div>
        </div>
      </>
    )
  } else {
    return null
  }
}

const horizontalMovement = {
  from: {
    marginLeft: 0,
  },
  to: {
    marginLeft: 100,
  },
}

const styles = StyleSheet.create({
  dragContainer: {
    position: 'absolute',
    // top:420,
    left: 650,
    '@media(min-width:556px) and (orientation:landscape)': {
      // top:194,
      // bottom: 33,
      top: '45vh',
      left: '35vw',
      // left: 310
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      top: 470,
    },
    '@media(min-width:1200px)': {
      // top:470,
      // left:650,
      top: '65vh',
      left: '40vw',
    },
    '@media(min-width:2560px)': {
      // top:470,
      // left:650,
      top: '70vh',
      left: '40vw',
    },
  },
  lottieSize: {
    width: 200,
    height: 100,
    '@media(min-width:556px) and (orientation:landscape)': {
      transform: 'scale(0.5)',
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      transform: 'scale(1)',
    },
    '@media(min-width:1200px)': {
      transform: 'scale(1)',
    },
    '@media(min-width:1600px)': {
      transform: 'scale(1)',
    },
    '@media(min-width:1920px)': {
      transform: 'scale(1.3)',
    },
    '@media(min-width:2560px)': {
      transform: 'scale(2)',
    },
  },
  dragContainer1: {
    position: 'absolute',
    textAlign: 'center',
    top: '60vh',
    left: '50vw',
    '@media(min-width:556px) and (orientation:portrait)': {
      display: 'none',
    },
  },
  lottieSize1: {
    width: 512 / 8,
    height: 783 / 8,
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1920px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 2560px)': {
      transform: 'scale(1.8)',
    },
  },
  handAnimation: {
    animationName: [horizontalMovement],
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
  },
  userPromptContainer: {
    position: 'absolute',
    // top:170,
    bottom: 320,
    left: 510,
    width: 200,
    height: 'auto',
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:landscape)': {
      transform: 'scale(0.7)',
      // bottom: 100,
      // left: 300
      bottom: '25vh',
      left: '50vw',
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      transform: 'scale(1)',
      bottom: 263,
      left: 770,
    },
    '@media(min-width:1200px)': {
      transform: 'scale(1)',
      // bottom: 263,
      // left:770,
      bottom: '35vh',
      left: '50vw',
    },
    '@media(min-width:1600px)': {
      transform: 'scale(1.3)',
      // bottom: 263,
      // left:770,
      bottom: '35vh',
      left: '50vw',
    },
    '@media(min-width:2560px)': {
      transform: 'scale(2)',
      // bottom: 263,
      // left:770,
      bottom: '35vh',
      left: '50vw',
    },
  },
  teleportPromptContainer: {
    position: 'absolute',
    top: 130,
    left: 795,
    width: 200,
    height: 'auto',
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:landscape)': {
      // top: 50,
      // left: 90,
      top: '15vh',
      left: '18vw',
      transform: 'scale(0.7)',
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      top: 130,
      left: 795,
      transform: 'scale(1)',
    },
    '@media(min-width:1200px)': {
      // top:130,
      // left:795,
      top: '20vh',
      left: '59vw',
      transform: 'scale(1)',
    },
    '@media(min-width:1600px)': {
      // top:130,
      // left:795,
      top: '20vh',
      left: '62vw',
      transform: 'scale(1.3)',
    },
    '@media(min-width:2560px)': {
      // top:130,
      // left:795,
      top: '20vh',
      left: '64vw',
      transform: 'scale(2)',
    },
  },
  automatedPromptContainer: {
    position: 'absolute',
    // top:650,
    bottom: 117,
    left: 880,
    width: 200,
    height: 'auto',
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media(min-width:556px) and (orientation:landscape)': {
      // bottom: 37,
      // left: 90,
      bottom: '10vh',
      left: '15vw',
      transform: 'scale(0.7)',
    },
    '@media(min-width:768px) and (orientation:landscape)': {
      // bottom: 37,
      // left: 90,
      bottom: '10vh',
      left: '20vw',
      transform: 'scale(0.7)',
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      bottom: '15vh',
      left: '64vw',
      transform: 'scale(1.2)',
    },
    '@media(min-width:1200px)': {
      // bottom: 117,
      // left:880,
      bottom: '15vh',
      left: '60vw',
      transform: 'scale(1.5)',
    },
    '@media(min-width:1400px)': {
      // bottom: 117,
      // left:880,
      bottom: '16vh',
      left: '60vw',
      transform: 'scale(1.3)',
    },
    '@media(min-width:1600px)': {
      // bottom: 117,
      // left:880,
      bottom: '15vh',
      left: '61vw',
      transform: 'scale(1.45)',
    },
    '@media(min-width:1920px)': {
      // bottom: 117,
      // left:880,
      bottom: '13vh',
      left: '59vw',
      transform: 'scale(1.8)',
    },
    '@media(min-width:2560px)': {
      // bottom: 117,
      // left:880,
      bottom: '12vh',
      left: '61vw',
      transform: 'scale(2.3)',
    },
  },
  TextContainer: {
    textAlign: 'center',
    position: 'absolute',
    top: 15,
    left: 10,
    width: '85%',
    '@media(min-width:556px) and (orientation:landscape)': {
      left: 20,
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      left: 20,
    },
  },
  TextContainer1: {
    textAlign: 'center',
    position: 'absolute',
    top: 15,
    left: 20,
    width: '85%',
  },
  TextContainer2: {
    textAlign: 'center',
    position: 'absolute',
    top: 8,
    left: 20,
    width: '85%',
  },
  dragText: {
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    fontSize: 15,
    lineHeight: '17px',
    textAlign: 'left',
  },
})
