import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { useHotspotState, useMenuButtons } from '../../store'
import Branding from '../core/Branding'
import animationData from '../assets/lottie/RotAnimLottie1.json'
import Lottie from 'react-lottie'
import Map from '../assets/Buttons/Map'
import InfoHS from '../assets/Hotspots/InfoHS'
import ViewHS from '../assets/Hotspots/ViewHS'
import DayLighting from '../assets/Buttons/DayLighting'
import Cross from '../assets/Buttons/Cross'
import { fadeIn, fadeOut } from 'react-animations'

function Guide() {
  const [componentVisibility, setComponentVisibility] = useState(false)
  const [fade, setFade] = useState(false)
  const { setShowGuide } = useMenuButtons()
  const { setHotspotVisibility } = useHotspotState()

  useEffect(() => {
    const unsub = useMenuButtons.subscribe(
      (paw, previousePaw) => {
        setHotspotVisibility(!paw)
        setComponentVisibility(paw)
      },
      (state) => state.showGuide
    )

    return function cleaup() {
      unsub()
    }
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  function animationEnd() {
    if (fade) {
      setComponentVisibility(false)
      setShowGuide(false)
      setFade(false)
    }
  }

  function onCrossClicked() {
    if (!fade) {
      setFade(true)
      setHotspotVisibility(true)
    }
  }

  return (
    <div
      className={css(
        componentVisibility ? styles.guideContainer : styles.displayNone,
        fade ? styles.screenfadeOut : styles.screenfadeIn
      )}
      onAnimationEnd={animationEnd}
    >
      <Branding />
      <button className={css(styles.cross)} onClick={onCrossClicked}>
        <Cross />
      </button>
      <div className={css(styles.hintContainer)}>
        <div className={css(styles.circleContainer)}>
          <div className={css(styles.circle)}>
            <Lottie options={defaultOptions} />
          </div>
          <div className={css(styles.lookAroundText)}>
            Click and drag the screen to look around
          </div>
        </div>
        <div className={css(styles.hints)}>
          <div className={css(styles.hintBlock)}>
            <div className={css(styles.iconSize)}>
              <Map />
            </div>
            <div className={css(styles.iconText)}>
              Click on the map to view your location
            </div>
          </div>
          <div className={css(styles.hintBlock)}>
            <div className={css(styles.iconSize)}>
              <DayLighting />
            </div>
            <div className={css(styles.iconText)}>
              Toggle between day and night lighting
            </div>
          </div>
          <div className={css(styles.hintBlock)}>
            <div className={css(styles.iconSize)}>
              <InfoHS />
            </div>
            <div className={css(styles.iconText1)}>
              Click on the hotspot for information
            </div>
          </div>
          <div className={css(styles.hintBlock)}>
            <div className={css(styles.iconSize)}>
              <ViewHS />
            </div>
            <div className={css(styles.iconText1)}>
              Click on the hotspot to view the space
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Guide

const styles = StyleSheet.create({
  guideContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#850C70E5',
    textAlign: 'center',
    zIndex: 13,
  },
  cross: {
    position: 'absolute',
    right: 24,
    top: 24,
    background: 'none',
    outline: 0,
    border: 'none',
    padding: 0,
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 24,
      height: 24,
      top: 11,
      right: 11,
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': {
      width: 24,
      height: 24,
      top: 11,
      right: 11,
    },
    // Large devices (desktops, 992px and up)
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 28,
      height: 28,
      top: 24,
      right: 24,
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      width: 28,
      height: 28,
      right: 24,
      top: 24,
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      width: 28,
      height: 28,
      right: 24,
      top: 24,
    },
    '@media (min-width: 1920px)': {
      width: 32,
      height: 32,
      right: 24,
      top: 24,
    },
    '@media (min-width: 2560px)': {
      width: 42,
      height: 42,
      right: 24,
      top: 24,
    },
  },
  hintContainer: {
    marginTop: 264,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content',
    overflow: 'hidden',
    '@media(min-width:556px) and (orientation:landscape)': {
      marginTop: 40,
    },
    '@media(min-width:768px) and (orientation:landscape)': {
      marginTop: 50,
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      marginTop: 164,
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      marginTop: 218,
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      marginTop: 218,
    },
    '@media (min-width: 1920px)': {
      marginTop: 264,
    },
    '@media (min-width: 2560px)': {
      marginTop: 364,
    },
  },
  circleContainer: {
    float: 'left',
    width: 'fit-content',
    textAlign: 'center',
  },
  circle: {
    width: 360,
    height: 360,
    marginLeft: 'auto',
    marginRight: 'auto',
    // Small devices (landscape phones, 576px and up)
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 160,
      height: 160,
      marginTop: 17,
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': {
      width: 160,
      height: 160,
      marginTop: 17,
    },
    // Large devices (desktops, 992px and up)
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 200,
      height: 'auto',
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      width: 300,
      height: 'auto',
      // marginTop: 218
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      width: 360,
      height: 360,
      marginTop: 0,
    },
    '@media (min-width: 1600px)': {
      width: 360,
      height: 360,
    },
    '@media (min-width: 2560px)': {
      width: 460,
      height: 460,
    },
  },
  lookAroundText: {
    fontFamily: 'DIN-Regular',
    color: '#ffffff',
    // Small devices (landscape phones, 576px and up)
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14.06px',
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14.06px',
    },
    // Large devices (desktops, 992px and up)
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 16,
      lineHeight: '18.06px',
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      fontSize: 22,
      lineHeight: '33px',
      color: '#ffffff',
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      fontSize: 22,
      lineHeight: '33px',
      color: '#ffffff',
    },
    '@media (min-width: 1600px)': {},
    '@media (min-width: 2560px)': {
      fontSize: 28,
      lineHeight: '39px',
      color: '#ffffff',
    },
  },
  hints: {
    float: 'left',
    width: 512,
    height: 'auto',
    backgroundColor: '#ffffffA6',
    borderRadius: '16px',
    marginLeft: 100,
    paddingTop: 16,
    paddingBottom: 16,
    // Small devices (landscape phones, 576px and up)
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 333.4,
      marginLeft: 37.6,
      paddingTop: 12,
      paddingBottom: 12,
    },
    '@media(min-width:768px) and (orientation:landscape)': {
      width: 333.4,
      marginLeft: 87.6,
      paddingTop: 12,
      paddingBottom: 12,
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      width: 433.4,
      marginLeft: 87.6,
      paddingTop: 16,
      paddingBottom: 16,
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      width: 512,
      height: 'auto',
      marginLeft: 100,
      marginTop: 20,
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      width: 512,
      height: 'auto',
      marginLeft: 100,
      marginTop: 20,
    },
    '@media (min-width: 1920px)': {
      marginLeft: 200,
    },
    '@media (min-width: 2560px)': {
      width: 652,
      height: 'auto',
      marginLeft: 200,
      marginTop: 40,
      paddingTop: 32,
      paddingBottom: 32,
    },
  },
  hintBlock: {
    overflow: 'hidden',
    marginTop: 8,
    marginBottom: 8,
    '@media(min-width:556px) and (orientation:landscape)': {
      textAlign: 'center',
    },
  },
  iconSize: {
    width: 20,
    height: 20,
    float: 'left',
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 32,
      height: 'auto',
      marginLeft: 29.4,
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': {
      width: 32,
      height: 'auto',
      marginLeft: 29.4,
    },
    // Large devices (desktops, 992px and up)
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 42,
      height: 'auto',
      marginLeft: 29.4,
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      width: 56,
      height: 'auto',
      marginLeft: 25,
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      width: 56,
      height: 'auto',
      marginLeft: 25,
    },
    '@media (min-width: 1600px)': {},
    '@media (min-width: 2560px)': {
      width: 66,
      height: 'auto',
      marginLeft: 35,
    },
  },
  iconText: {
    fontFamily: 'DIN-Regular',
    color: '#850C70E5',
    float: 'left',

    // Small devices (landscape phones, 576px and up)
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
      marginTop: 8,
      paddingLeft: 17.8,
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
      marginTop: 8,
      paddingLeft: 17.8,
    },
    // Large devices (desktops, 992px and up)
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 16,
      lineHeight: '18px',
      marginTop: 12,
      paddingLeft: 17.8,
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      fontSize: 22,
      lineHeight: '28px',
      marginTop: 16,
      paddingLeft: 35,
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      fontSize: 22,
      lineHeight: '28px',
      marginTop: 16,
      paddingLeft: 35,
    },
    '@media (min-width: 1600px)': {},
    '@media (min-width: 2560px)': {
      fontSize: 28,
      lineHeight: '34px',
      marginTop: 16,
      paddingLeft: 35,
    },
  },
  iconText1: {
    fontFamily: 'DIN-Regular',
    color: '#850C70E5',
    float: 'left',

    // Small devices (landscape phones, 576px and up)
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
      marginTop: 8,
      paddingLeft: 17.8,
    },
    // Medium devices (tablets, 768px and up)
    '@media (min-width: 768px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
      marginTop: 8,
      paddingLeft: 17.8,
    },
    // Large devices (desktops, 992px and up)
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 16,
      lineHeight: '18px',
      marginTop: 12,
      paddingLeft: 17.8,
    },
    // X-Large devices (large desktops, 1200px and up)
    '@media (min-width: 1200px)': {
      fontSize: 22,
      lineHeight: '28px',
      marginTop: 21,
      paddingLeft: 35,
    },
    // XX-Large devices (larger desktops, 1400px and up)
    '@media (min-width: 1400px)': {
      fontSize: 22,
      lineHeight: '28px',
      marginTop: 21,
      paddingLeft: 35,
    },
    '@media (min-width: 1600px)': {},
    '@media (min-width: 2560px)': {
      fontSize: 28,
      lineHeight: '34px',
      marginTop: 21,
      paddingLeft: 35,
    },
  },
  displayNone: {
    display: 'none',
    pointerEvents: 'none',
  },
  screenfadeIn: {
    animationName: [fadeIn],
    animationDuration: '1s',
  },
  screenfadeOut: {
    animationName: fadeOut,
    animationDuration: '1s',
  },
})
