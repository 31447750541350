import React from 'react'

function FreeRoam(props) {
  return (
    <svg
      width="118"
      height="118"
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.621 6.5914C42.314 7.7207 31.1593 12.8141 22.3785 21.3184C14.1738 29.2465 8.84995 39.5945 7.02925 51.0949L6.86792 52.0859H9.77183C12.5835 52.0859 12.6757 52.0629 12.7679 51.5559C12.814 51.2793 13.1828 49.6891 13.5746 48.0066C17.3773 31.5051 30.6984 17.9074 47.1308 13.7359C51.648 12.6066 53.7222 12.3531 58.8847 12.3531C64.0472 12.3531 66.1214 12.6066 70.6386 13.7359C87.071 17.9074 100.392 31.5051 104.195 48.0066C104.587 49.6891 104.955 51.2793 105.002 51.5559C105.094 52.0629 105.186 52.0859 107.998 52.0859H110.902L110.74 51.0949C109.887 45.702 108.297 40.6547 105.923 35.8379C103.066 30.0762 99.8621 25.6512 95.391 21.3184C87.532 13.6898 78.0828 8.94219 67.1816 7.12148C64.4851 6.68359 57.1332 6.36094 54.621 6.5914Z"
        fill="white"
      />
      <path
        d="M7.02925 66.5364C7.97417 73.22 10.7167 80.7103 14.3582 86.5872C18.207 92.7868 24.0839 98.779 30.3066 102.858C32.98 104.587 38.6265 107.375 41.6226 108.39C47.5457 110.441 52.1781 111.178 58.8847 111.178C64.3238 111.178 66.7437 110.925 71.4222 109.772C82.3003 107.145 92.5331 100.646 99.6316 91.8419C105.439 84.6282 109.473 75.5017 110.74 66.5364L110.902 65.4532H108.021H105.117L104.978 66.306C101.936 84.5591 88.2464 99.5626 70.6386 104.034C66.1214 105.163 64.0472 105.417 58.8847 105.417C53.7222 105.417 51.648 105.163 47.1308 104.034C30.7906 99.8853 17.4925 86.3798 13.6207 69.9935C13.2519 68.4493 12.8832 66.7899 12.791 66.306L12.6527 65.4532H9.74878H6.86792L7.02925 66.5364Z"
        fill="white"
      />
      <path
        d="M97.4102 59C97.4102 80.2133 80.2134 97.4102 59 97.4102C37.7867 97.4102 20.5898 80.2133 20.5898 59C20.5898 37.7867 37.7867 20.5898 59 20.5898C80.2134 20.5898 97.4102 37.7867 97.4102 59Z"
        fill="white"
        fillOpacity={props.opacity}
        stroke="white"
        stroke-width="2"
      />
    </svg>
  )
}

export default FreeRoam
