import React from 'react'

function MasterBedroomHS(props) {
  return (
    <svg
      width="50"
      height="64"
      viewBox="0 0 50 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1504 46.958C12.8661 47.9852 13.6277 49.0745 14.4378 50.2332C16.7593 53.5537 19.4793 57.4442 22.6615 62.0761C23.8322 63.7742 26.3367 63.7743 27.5076 62.0761C30.6898 57.4442 33.4098 53.5537 35.7313 50.2332C36.5414 49.0745 37.303 47.9852 38.0187 46.958C34.2141 49.1599 29.7965 50.4202 25.0846 50.4202C20.3726 50.4202 15.955 49.1599 12.1504 46.958Z"
        fill="white"
        fillOpacity={props.opacity}
      />
      <rect
        x="1.24646"
        y="1.06348"
        width="47.6761"
        height="47.6761"
        rx="23.838"
        stroke="white"
      />
      <rect
        x="3.7887"
        y="3.60596"
        width="42.5916"
        height="42.5916"
        rx="21.2958"
        fill="white"
        fillOpacity={props.opacity}
      />
      <path
        d="M32.4366 26.9296C33.1467 26.9296 33.8301 27.2005 34.3474 27.6871C34.8647 28.1736 35.1768 28.8392 35.2202 29.548L35.2253 29.7184V36.3099C35.2252 36.5026 35.152 36.6881 35.0205 36.8289C34.8889 36.9697 34.7088 37.0554 34.5166 37.0685C34.3243 37.0816 34.1342 37.0213 33.9848 36.8997C33.8353 36.7781 33.7375 36.6043 33.7113 36.4134L33.7042 36.3099V34.0282H16.4647V36.3099C16.4647 36.4937 16.3982 36.6713 16.2774 36.8098C16.1566 36.9483 15.9897 37.0384 15.8076 37.0634L15.7042 37.0705C15.5204 37.0705 15.3428 37.0039 15.2043 36.8831C15.0658 36.7623 14.9757 36.5954 14.9507 36.4134L14.9436 36.3099V29.7184C14.9436 29.0082 15.2145 28.3248 15.701 27.8075C16.1876 27.2903 16.8531 26.9781 17.562 26.9347L17.7323 26.9296H32.4366ZM19.7605 19.8311H30.4084C31.1185 19.831 31.802 20.1019 32.3192 20.5885C32.8365 21.075 33.1487 21.7406 33.1921 22.4494L33.1971 22.6198V25.9156H30.1549L30.1478 25.7969C30.1211 25.5706 30.0189 25.3598 29.8577 25.1986C29.6966 25.0374 29.4858 24.9353 29.2594 24.9086L29.1408 24.9015H27.1126C26.8642 24.9015 26.6245 24.9927 26.4389 25.1578C26.2533 25.3228 26.1347 25.5502 26.1056 25.7969L26.0985 25.9156H24.0704L24.0633 25.7969C24.0366 25.5706 23.9344 25.3598 23.7732 25.1986C23.6121 25.0374 23.4013 24.9353 23.1749 24.9086L23.0563 24.9015H21.0281C20.7797 24.9015 20.54 24.9927 20.3544 25.1578C20.1688 25.3228 20.0502 25.5502 20.0211 25.7969L20.014 25.9156H16.9718V22.6198C16.9717 21.9096 17.2426 21.2262 17.7292 20.709C18.2157 20.1917 18.8813 19.8795 19.5901 19.8361L19.7605 19.8311Z"
        fill="#850C70"
      />
      <path
        d="M28.9387 14.1384C28.8591 14.073 28.7623 14.0309 28.6595 14.017C28.5566 14.0031 28.4519 14.0178 28.3572 14.0597L26.5964 14.8295L25.5712 13.0142C25.523 12.9288 25.4524 12.8576 25.3668 12.8081C25.2812 12.7585 25.1837 12.7324 25.0844 12.7324C24.985 12.7324 24.8875 12.7585 24.8019 12.8081C24.7163 12.8576 24.6457 12.9288 24.5975 13.0142L23.5722 14.8295L21.8112 14.0595C21.7164 14.0181 21.6118 14.0035 21.5091 14.0174C21.4064 14.0313 21.3096 14.0731 21.2298 14.1382C21.1499 14.2033 21.0901 14.2889 21.0571 14.3856C21.0241 14.4823 21.0191 14.5861 21.0428 14.6854L21.9285 18.3949C21.9454 18.4667 21.9769 18.5344 22.0213 18.5938C22.0656 18.6533 22.1218 18.7032 22.1864 18.7405C22.2513 18.7781 22.3233 18.8025 22.398 18.8122C22.4727 18.8219 22.5486 18.8167 22.6213 18.797C24.2326 18.3594 25.9343 18.3593 27.5457 18.7965C27.6183 18.8162 27.6942 18.8214 27.7689 18.8117C27.8436 18.802 27.9155 18.7776 27.9804 18.74C28.045 18.7027 28.1011 18.6528 28.1455 18.5935C28.1898 18.5341 28.2214 18.4665 28.2383 18.3947L29.1256 14.6858C29.1497 14.5865 29.145 14.4826 29.112 14.3858C29.0789 14.289 29.0189 14.2033 28.9387 14.1384L28.9387 14.1384Z"
        fill="#850C70"
      />
    </svg>
  )
}

export default MasterBedroomHS
