import { useEffect } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import * as THREE from 'three'

function Tween(props) {
  const { camera } = useThree()
  let targetPos = new THREE.Vector3(0, 0, 0)
  let targetControl = new THREE.Vector3(0, 0, 0)
  let targetPos1 = new THREE.Vector3(0, 0, 0)
  let targetControl1 = new THREE.Vector3(0, 0, 0)

  useEffect(() => {
    //if custom
    // if (props.isCustomTween === true) {
    //     targetPos = new THREE.Vector3(props.customTweenPoint[0], props.customTweenPoint[1], props.customTweenPoint[2]);
    //     targetControl = new THREE.Vector3(props.customControlLookat[0], props.customControlLookat[1], props.customControlLookat[2]);
    // }
    // else {
    // if(props.chainTween === true){
    //     targetPos = new THREE.Vector3(props.tweenPoint.position[0], props.tweenPoint.position[1], props.tweenPoint.position[2]);
    //     targetControl = new THREE.Vector3(props.tweenPoint.control[0], props.tweenPoint.control[1], props.tweenPoint.control[2]);
    //     targetPos1 = new THREE.Vector3(props.tweenPoint.position1[0], props.tweenPoint.position1[1], props.tweenPoint.position1[2]);
    //     targetControl1 = new THREE.Vector3(props.tweenPoint.control1[0], props.tweenPoint.control1[1], props.tweenPoint.control1[2])
    // }
    // else{
    targetPos = new THREE.Vector3(
      props.tweenPoint[0],
      props.tweenPoint[1],
      props.tweenPoint[2]
    )
    // targetControl = new THREE.Vector3(props.tweenPoint.control[0], props.tweenPoint.control[1], props.tweenPoint.control[2]);
    // }

    // }
    let tween = new TWEEN.Tween(camera.position)
      .to(targetPos, 800)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onStart(function () {
        props.onTweenStart('Started')
        // new TWEEN.Tween(props.controls.current.target)
        //     .to(targetControl, props.controlDuration)
        //     .easing(TWEEN.Easing.Quadratic.InOut)
        // .onUpdate(function (d) {
        //     // camera.lookAt(targetControl)
        // })

        // .start();
      })
      .onComplete(function () {
        props.onTweenFinish('FInish')
      })
      .start()
    return () => {
      tween.stop()
    }
  }, [
    props.tweenPoint,
    // props.controls, // orbit control ref
    // props.customTweenPoint,
    // props.customControlLookat,
    // props.isCustomTween,
    // props.tweenInterpolation,
    // props.tweenDelay
  ])

  useFrame(() => {
    TWEEN.update()
  })
  return null
}

export default Tween
