import React from 'react'

function LivingRoomHS(props) {
  return (
    <svg
      width="49"
      height="64"
      viewBox="0 0 49 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4319 47.3804C12.1476 48.4075 12.9092 49.4969 13.7193 50.6556C16.0408 53.9761 18.7608 57.8666 21.943 62.4985C23.1137 64.1966 25.6182 64.1967 26.7891 62.4985C29.9713 57.8666 32.6913 53.9761 35.0128 50.6556C35.8229 49.4969 36.5845 48.4075 37.3002 47.3804C33.4956 49.5822 29.078 50.8425 24.366 50.8425C19.6541 50.8425 15.2365 49.5822 11.4319 47.3804Z"
        fill="white"
        fillOpacity={props.opacity}
      />
      <rect
        x="0.528076"
        y="1.48584"
        width="47.6761"
        height="47.6761"
        rx="23.838"
        stroke="white"
      />
      <rect
        x="3.07031"
        y="4.02832"
        width="42.5916"
        height="42.5916"
        rx="21.2958"
        fill="white"
        fillOpacity={props.opacity}
      />
      <path
        d="M18.2815 23.9546V26.3884H30.4505V23.9546C30.4505 22.6122 31.542 21.5208 32.8843 21.5208H34.1012C34.1012 19.5053 32.466 17.8701 30.4505 17.8701H18.2815C16.266 17.8701 14.6308 19.5053 14.6308 21.5208H15.8477C17.1901 21.5208 18.2815 22.6122 18.2815 23.9546ZM34.1012 22.7377H32.8843C32.2112 22.7377 31.6674 23.2815 31.6674 23.9546V27.6053H17.0646V23.9546C17.0646 23.2815 16.5208 22.7377 15.8477 22.7377H14.6308C13.2884 22.7377 12.197 23.8291 12.197 25.1715C12.197 26.069 12.6914 26.8448 13.4139 27.2669V31.8645C13.4139 32.1991 13.6877 32.4729 14.0224 32.4729H16.4562C16.7908 32.4729 17.0646 32.1991 17.0646 31.8645V31.256H31.6674V31.8645C31.6674 32.1991 31.9412 32.4729 32.2759 32.4729H34.7097C35.0443 32.4729 35.3182 32.1991 35.3182 31.8645V27.2669C36.0407 26.8448 36.5351 26.069 36.5351 25.1715C36.5351 23.8291 35.4436 22.7377 34.1012 22.7377Z"
        fill="#850C70"
      />
    </svg>
  )
}

export default LivingRoomHS
