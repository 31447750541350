import { isMobile } from 'react-device-detect'
import create from 'zustand'
import BathroomHS from './components/assets/Hotspots/BathroomHS'
import BedroomHS from './components/assets/Hotspots/BedroomHS'
import KitchenHS from './components/assets/Hotspots/KitchenHS'
import LivingRoomHS from './components/assets/Hotspots/LivingRoomHS'
import MasterBedroomHS from './components/assets/Hotspots/MasterBedroomHS'
import { Timer } from './components/core/Timer'

export const useCameraControls = create((set) => ({
  cameraValues: [{ position: [0, 0, 0], lookAt: [0, 0, 0] }],
  setCamValues: (Values) => {
    set((state) => ({
      cameraValues: [{ position: Values.position, lookAt: Values.lookAt }],
    }))
  },
}))

export const useCamRef = create((set) => ({
  controlRef: null,
  cameraRef: null,
  setControlRef: (ref) => {
    set((state) => ({ controlRef: ref }))
  },
  setCameraRef: (ref) => {
    set((state) => ({ cameraRef: ref }))
  },
}))

export const useApartmentPreview = create((set) => ({
  apartmentPreview: false, //Default : false
  lottieState: false,
  setLottieState: (bool) => {
    set((state) => ({ lottieState: bool }))
  },
  setApartmentPreview: (bool) => {
    set((state) => ({ apartmentPreview: bool }))
  },
}))

export const useGuidedTour = create((set) => ({
  guidedTour: false,
  pauseTour: false,
  tourCompleted: false,
  infoPopup: false,
  pauseState: false,
  showSeconds: 10,
  setShowSeconds: (num) => {
    set((state) => ({ showSeconds: num }))
  },
  setPauseState: (bool) => {
    set((state) => ({ pauseState: bool }))
  },
  setInfoPopup: (bool) => {
    set((state) => ({ infoPopup: bool }))
  },
  setGuidedTour: (bool) => {
    set((state) => ({ guidedTour: bool }))
  },
  setPauseTour: (bool) => {
    set((state) => ({ pauseTour: bool }))
  },
  setTourCompleted: (bool) => {
    set((state) => ({ tourCompleted: bool }))
  },
}))

export const useTimeOfDaySwitch = create((set) => ({
  lighting: true,
  setLighting: (bool) => {
    set((state) => ({ lighting: bool }))
  },
}))

export const useTweenFinish = create((set) => ({
  tweenState: false,
  setTweenState: (bool) => {
    set((state) => ({ tweenState: bool }))
  },
}))

export const useMenuButtons = create((set) => ({
  showGuide: false,
  showMap: false,
  setShowGuide: (bool) => {
    set((state) => ({ showGuide: bool }))
  },
  setShowMap: (bool) => {
    set((state) => ({ showMap: bool }))
  },
}))

export const useHotspotState = create((set) => ({
  hotspotVisibility: false, //Default : false
  setHotspotVisibility: (bool) => {
    set((state) => ({ hotspotVisibility: bool }))
  },
}))

export const useTutorial = create((set, get) => ({
  showOnce: true,
  showHint: false,
  setShowHint: (bool) => {
    set((state) => ({ showHint: bool }))
    if (bool) Timer()
  },
  setShowOnce: (bool) => {
    set((state) => ({ showOnce: bool }))
  },
}))

export const useTutorial1 = create((set, get) => ({
  count: 0,
  setCount: () => {
    if (get().count === 4) {
    } else {
      let temp = get().count + 1
      set((state) => ({ count: temp }))
    }
  },
}))

export const useMaster = create((set, get) => ({
  objects: {
    '/2bhk': {
      hotspots: [],
    },
    '/2bhk/home': {
      title: '2BHK - A',
      tower: 'T1 - 01',
      hotspots: [
        {
          position: [2, 4, -0.5],
          mobilePosition: [4, 6, -1.5],
          width: 75,
          height: 95,
          title: 'Bathroom',
          target: '/2bhk/bathroom',
          svg: <BathroomHS opacity={0.75} />,
          svgHover: <BathroomHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [-3, 4, 1],
          mobilePosition: [-3, 6, 1.5],
          scale: 1,
          title: 'Toilet',
          target: '/2bhk/toilet',
          svg: <BathroomHS opacity={0.75} />,
          svgHover: <BathroomHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [2, 4, -3],
          mobilePosition: [4, 6, -5],
          scale: 1,
          title: 'MasterBedroom',
          target: '/2bhk/masterbedroom1',
          svgHover: <MasterBedroomHS opacity={1} />,
          svg: <MasterBedroomHS opacity={0.75} />,
          type: 'apartmentPreview',
        },
        {
          position: [-2, 4, -3],
          mobilePosition: [-3, 6, -5],
          scale: 1,
          title: 'Bedroom',
          target: '/2bhk/bedroom',
          svg: <BedroomHS opacity={0.75} />,
          svgHover: <BedroomHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [2, 4, 1.3],
          mobilePosition: [4, 6, 1.5],
          scale: 1,
          title: 'Kitchen',
          target: '/2bhk/kitchen',
          svg: <KitchenHS opacity={0.75} />,
          svgHover: <KitchenHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [0, 4, 4],
          mobilePosition: [-1, 6, 5],
          scale: 1,
          title: 'LivingRoom',
          target: '/2bhk/livingroom1',
          svg: <LivingRoomHS opacity={0.75} />,
          svgHover: <LivingRoomHS opacity={1} />,
          type: 'apartmentPreview',
        },
      ],
    },
    '/2bhk/bathroom': {
      title: 'MASTER TOILET',
      url: '/360renders/Daylight/2BHKAMBathroomDL.webp',
      nightUrl: '/360renders/Nightlight/2BHKAMBathroomNL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKAMBathroomDL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKAMBathroomNL.webp',
      hotspots: [
        {
          position: [-200, -25, 0],
          mobilePosition: [-294.839, -38.566, 5.466],
          width: 75,
          height: 100,
          title: 'M.TOILET',
          dimensions: `8'4" X 4'7"`,
          target: '/2bhk/bathroom',
          type: 'Info',
        },
        {
          position: [208, -284, 30],
          mobilePosition: [262.44, -128.32, -62.295],
          mobileScale: [30, 30, 30],
          scale: [22, 22, 22],
          rotation: [1.6, 0, 1.6],
          title: 'MasterBedroom1',
          target: '/2bhk/masterbedroom1',
          type: 'FreeRoam',
        },
        {
          position: [311, -156, -69],
          mobilePosition: [178.17, -240.621, 14.09],
          mobileScale: [30, 30, 30],
          scale: [14, 14, 14],
          rotation: [1.6, 0, 1.6],
          title: 'MasterBedroom1',
          target: '/2bhk/passage2',
          type: 'FreeRoam',
        },
      ],

      camera: { position: [0, 0, 0], lookAt: [10.049, 4.592, 7.314] },
      mapMarkerPosition: {
        right: -16.4,
        bottom: 11,
        mobileX: -24,
        mobileY: 18,
      },
    },
    '/2bhk/toilet': {
      title: 'Toilet - 1',
      url: '/360renders/Daylight/2BHKAToiletDL.webp',
      nightUrl: '/360renders/Nightlight/2BHKAToiletNL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKAToiletDL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKAToiletNL.webp',
      hotspots: [
        {
          position: [-200, -19, 0],
          mobilePosition: [-296.618, -26.808, 17.368],
          width: 75,
          height: 100,
          title: 'TOILET - 1',
          dimensions: `8'2" X 4'9"`,
          target: '/2bhk/bathroom',
          type: 'Info',
        },
        {
          position: [173, -211, -7],
          mobilePosition: [195.516, -228.138, -10.961],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'Toilet',
          target: '/2bhk/passage',
          type: 'FreeRoam',
        },
        {
          position: [325, -122, -6],
          mobilePosition: [304.253, -111.911, -13.202],
          mobileScale: [25, 25, 25],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Toilet',
          target: '/2bhk/kitchen',
          type: 'FreeRoam',
        },
        {
          position: [173, -211, -7],
          mobilePosition: [195.516, -228.138, -10.961],
          mobileScale: [25, 25, 25],
          scale: [18, 18, 18],
          rotation: [-1.5, 0, 0],
          title: 'Toilet',
          target: '/2bhk/kitchen',
          type: 'Arrow',
        },
      ],

      camera: { position: [0, 0, 0], lookAt: [8.836, 5.037, 8.491] },
      mapMarkerPosition: {
        right: -10.9,
        bottom: 26.8,
        mobileX: -15.9,
        mobileY: 42.8,
      },
    },
    '/2bhk/masterbedroom2': {
      title: 'Master Bedroom',
      url: '/360renders/Daylight/2BHKAMBedroomDL.webp',
      nightUrl: '/360renders/Nightlight/2BHKAMBedroomNL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKAMBedroomDL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKAMBedroomNL.webp',
      hotspots: [
        {
          position: [-625, -32, -121],
          mobilePosition: [-288.018, -34.774, -66.169],
          width: 75,
          height: 100,
          title: 'M.BEDROOM',
          dimensions: `13'9" X 10'0"`,
          target: '/2bhk/masterbedroom',
          type: 'Info',
        },
        {
          position: [86.517, -227.8, -164.315],
          mobilePosition: [86.517, -227.8, -164.315],
          mobileScale: [30, 30, 30],
          scale: [20.0, 20.0, 20.0],
          rotation: [1.6, 0, 0.6],
          title: 'MasterBedroom',
          target: '/2bhk/masterbedroom1',
          type: 'FreeRoam',
        },
        // { position: [89, -140, -57], mobilePosition: [1.4, 1.8, 0], scale: [7.5,7.5,7.5], rotation:[1.6,0,0.6], title: "MasterBedroom",target: '/masterbedroom1', type:'Arrow'  },
        // { position: [-120, -140, 101], mobilePosition: [1.4, 1.8, 0], scale: [7.5,7.5,7.5], rotation:[1.6,0,1.0], title: "MasterBedroom",target: '/masterbedroom1', type:'Arrow'  }
      ],
      camera: { position: [0, 0, 0], lookAt: [12.705, 1.331, -3.517] },
      mapMarkerPosition: {
        right: -19,
        bottom: 15,
        mobileX: -28,
        mobileY: 24,
      },
    },
    '/2bhk/masterbedroom1': {
      title: 'Master Bedroom',
      url: '/360renders/Daylight/2BHKAMBedroomAntespaceDL.webp',
      nightUrl: '/360renders/Nightlight/2BHKAMBedroomAntespaceNL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKAMBedroomAntespaceDL.webp',
      mobileNightUrl:
        '/360renders/Nightlight/Mobile/Mob2BHKAMBedroomAntespaceNL.webp',
      hotspots: [
        {
          position: [-213, -218, -12],
          mobilePosition: [-227.969, -166.677, -88.326],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'MasterBedroom1',
          target: '/2bhk/masterbedroom2',
          type: 'FreeRoam',
        },
        {
          position: [-176, -218, -27],
          mobilePosition: [-185.276, -227.689, -26.073],
          mobileScale: [30, 30, 30],
          scale: [15, 15, 15],
          rotation: [1.6, 0, 1.6],
          title: 'MasterBedroom1',
          target: '/2bhk/masterbedroom2',
          type: 'Arrow',
        },
        {
          position: [-45.871, -229.454, -173.475],
          mobilePosition: [-45.871, -229.454, -173.475],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 0],
          title: 'MasterBedroom1',
          target: '/2bhk/bathroom',
          type: 'FreeRoam',
        },
        {
          position: [211, -356, 554],
          mobilePosition: [100.119, -154.409, 232.348],
          mobileScale: [30, 30, 30],
          scale: [24, 24, 24],
          rotation: [1.6, 0, 0],
          title: 'MasterBedroom1',
          target: '/2bhk/passage2',
          type: 'FreeRoam',
        },
        {
          position: [70, -140, 100],
          mobilePosition: [114.642, -221.339, 158.438],
          mobileScale: [30, 30, 30],
          scale: [7.5, 7.5, 7.5],
          rotation: [1.6, 0, 2.4],
          title: 'MasterBedroom1',
          target: '/2bhk/masterbedroom2',
          type: 'Arrow',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [12.323, 0.094, 4.867] },
      mapMarkerPosition: {
        right: -16.5,
        bottom: 14.8,
        mobileX: -25,
        mobileY: 24,
      },
    },
    '/2bhk/bedroom': {
      title: 'Bedroom - 1',
      url: '/360renders/Daylight/2BHKABedroom2DL.webp',
      nightUrl: '/360renders/Nightlight/2BHKABedroom2NL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKABedroom2DL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKABedroom2NL.webp',
      hotspots: [
        {
          position: [125, -126, 340],
          mobilePosition: [98.129, -97.473, 263.284],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, -0.2],
          title: 'Bathroom',
          target: '/2bhk/passage',
          type: 'FreeRoam',
        },
        {
          position: [80, -126, 150],
          mobilePosition: [106.058, -184.728, 202.939],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, -0.2],
          title: 'Bathroom',
          target: '/2bhk/passage2',
          type: 'FreeRoam',
        },
        {
          position: [74, -126, 125],
          mobilePosition: [106.058, -184.728, 202.939],
          mobileScale: [30, 30, 30],
          scale: [12.0, 12.0, 12.0],
          rotation: [1.6, 0, -0.2],
          title: 'Bathroom',
          target: '/2bhk/passage2',
          type: 'Arrow',
        },
        //{ position: [-83, -103, -111], mobilePosition: [1.4, 1.8, 0], scale: [7.5,7.5,7.5], rotation:[1.6,0,2.6], title: "Bathroom",target: '/passage', type:'Arrow'  },
        {
          position: [-527, -108, -160],
          mobilePosition: [-276.61, -65.736, -81.219],
          width: 75,
          height: 100,
          title: 'BEDROOM - 1',
          dimensions: `11'6" X 11'4"`,
          target: '/2bhk/bedroom',
          type: 'Info',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [11.276, 2.224, 6.594] },
      mapMarkerPosition: {
        right: -19.8,
        bottom: 23,
        mobileX: -29.8,
        mobileY: 37,
      },
    },
    '/2bhk/passage2': {
      title: 'Passage',
      url: '/360renders/Daylight/2BHKAPassage2DL.webp',
      nightUrl: '/360renders/Nightlight/2BHKAPassage2NL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKAPassage2DL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKAPassage2NL.webp',
      hotspots: [
        {
          position: [-343, -234, 104],
          mobilePosition: [-234.496, -158.814, 71.983],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'Passage2',
          target: '/2bhk/bedroom',
          type: 'FreeRoam',
        },
        {
          position: [-261, -234, 53],
          mobilePosition: [-210.501, -198.411, 55.494],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.2],
          title: 'Passage2',
          target: '/2bhk/bedroom',
          type: 'Arrow',
        },
        {
          position: [-139, -234, -372],
          mobilePosition: [-90.52, -151.746, -239.475],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 0],
          title: 'Passage2',
          target: '/2bhk/masterbedroom1',
          type: 'FreeRoam',
        },
        {
          position: [-139, -234, -567],
          mobilePosition: [-67.643, -113.43, -263.699],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 0],
          title: 'Passage2',
          target: '/2bhk/bathroom',
          type: 'FreeRoam',
        },
        {
          position: [374, -234, 3],
          mobilePosition: [256.639, -144.217, 3.296],
          mobileScale: [30, 30, 30],
          scale: [22, 22, 22],
          rotation: [1.6, 0, 1.6],
          title: 'Passage2',
          target: '/2bhk/passage',
          type: 'FreeRoam',
        },
        //{ position: [626, -234, 7], mobilePosition: [1.4, 1.8, 0], scale: [20,20,20], rotation:[1.6,0,1.6], title: "Passage2",target: '/livingroom2', type:'FreeRoam'  },
        {
          position: [-39, -234, -234],
          mobilePosition: [-33.319, -199.927, -215.596],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 2.2],
          title: 'Passage2',
          target: '/2bhk/masterbedroom1',
          type: 'Arrow',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [12.323, 0.094, 4.867] },
      mapMarkerPosition: {
        right: 128,
        bottom: 60,
        mobileX: -22.3,
        mobileY: 34.5,
      },
    },
    '/2bhk/kitchen': {
      title: 'Kitchen',
      url: '/360renders/Daylight/2BHKAKitchenDL.webp',
      nightUrl: '/360renders/Nightlight/2BHKAKitchenNL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKAKitchenDL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKAKitchenNL.webp',
      hotspots: [
        {
          position: [104, -130, -12],
          mobilePosition: [183.222, -228.107, -28.304],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 1.6],
          title: 'Kitchen',
          target: '/2bhk/passage',
          type: 'FreeRoam',
        },
        {
          position: [222, -130, -12],
          mobilePosition: [249.834, -155.901, -28.304],
          mobileScale: [25, 25, 25],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 1.6],
          title: 'Kitchen',
          target: '/2bhk/toilet',
          type: 'FreeRoam',
        },
        //Info Kitchen
        {
          position: [-236.978, -32.113, 176.6],
          mobilePosition: [-236.978, -32.113, 176.6],
          width: 90,
          height: 120,
          title: 'KITCHEN',
          dimensions: `9'4" X 7'1"`,
          target: '/2bhk/kitchen',
          type: 'Info',
        },
        {
          position: [-211, -130, 11],
          mobilePosition: [-262.617, -135.194, 14.686],
          mobileScale: [30, 30, 30],
          scale: [10.0, 10.0, 10.0],
          rotation: [1.6, 0, 1.6],
          title: 'Kitchen',
          target: '/2bhk/passage',
          type: 'Arrow',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.109, 1.555, 1.144] },
      mapMarkerPosition: {
        right: -11.2,
        bottom: 12,
        mobileX: -16.5,
        mobileY: 20,
      },
    },
    '/2bhk/livingroom1': {
      title: 'Living + Dining',
      url: '/360renders/Daylight/2BHKALivRoom1DL.webp',
      nightUrl: '/360renders/Nightlight/2BHKALivRoom1NL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKALivRoom1DL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKALivRoom1NL.webp',
      hotspots: [
        {
          position: [-343, -92, -93],
          mobilePosition: [-277.701, -75.235, -72.196],
          mobileScale: [30, 30, 30],
          scale: [7.5, 7.5, 7.5],
          rotation: [1.6, 0, 2],
          title: 'LivingRoom1',
          target: '/2bhk/livingroom2',
          type: 'FreeRoam',
        },
        {
          position: [-110, -5, 100],
          mobilePosition: [-228.499, -32.128, 189.492],
          width: 75,
          height: 100,
          title: 'LIVING+DINING',
          dimensions: `21'8" X 10'0"`,
          target: '/2bhk/livingroom',
          type: 'Info',
        },
        {
          position: [-173, -74, -39],
          mobilePosition: [-264.453, -113.516, -58.137],
          mobileScale: [30, 30, 30],
          scale: [7.5, 7.5, 7.5],
          rotation: [1.6, 0, 2.2],
          title: 'LivingRoom1',
          target: '/2bhk/livingroom2',
          type: 'Arrow',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.098, 1.219, 1.593] },
      mapMarkerPosition: {
        right: -1.5,
        bottom: 35,
        mobileX: -2.5,
        mobileY: 56,
        // position: [81,0]
      },
    },
    '/2bhk/livingroom2': {
      title: 'Living + Dining',
      url: '/360renders/Daylight/2BHKALivRoom2DL.webp',
      nightUrl: '/360renders/Nightlight/2BHKALivRoom2NL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKALivRoom2DL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKALivRoom2NL.webp',
      hotspots: [
        {
          position: [341, -101, 101],
          mobilePosition: [269.998, -84.684, 74.566],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 2],
          title: 'LivingRoom2',
          target: '/2bhk/livingroom1',
          type: 'FreeRoam',
        },
        {
          position: [157, -101, 34],
          mobilePosition: [242.46, -156.466, 40.545],
          mobileScale: [30, 30, 30],
          scale: [7.5, 7.5, 7.5],
          rotation: [1.6, 0, 1.95],
          title: 'LivingRoom2',
          target: '/2bhk/livingroom1',
          type: 'Arrow',
        },
        {
          position: [-20, -89, 260],
          mobilePosition: [-11.757, -95.048, 278.919],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 0.4],
          title: 'LivingRoom2',
          target: '/2bhk/passage',
          type: 'FreeRoam',
        },
        //{ position: [-126, -89, 239], mobilePosition: [1.6, 1.8, 0], scale: [8,8,8], rotation:[1.6,0,0.4], title: "LivingRoom2",target: '/passage2', type:'FreeRoam'  },
        {
          position: [14, -89, 178],
          mobilePosition: [11.864, -124.525, 265.921],
          mobileScale: [30, 30, 30],
          scale: [10.0, 10.0, 10.0],
          rotation: [1.6, 0, 0.6],
          title: 'LivingRoom2',
          target: '/2bhk/passage',
          type: 'Arrow',
        },
        {
          position: [221, 13, 208],
          mobilePosition: [213.943, -4.363, 205.312],
          width: 65,
          height: 90,
          title: 'LIVING+DINING',
          dimensions: `21'8" X 10'0"`,
          target: '/2bhk/livingroom',
          type: 'Info',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.147, 0, 1.936] },
      mapMarkerPosition: {
        right: -3.2,
        bottom: 23,
        mobileX: -4.5,
        mobileY: 38,
      },
    },
    '/2bhk/passage': {
      title: 'Passage',
      url: '/360renders/Daylight/2BHKAPassage1DL.webp',
      nightUrl: '/360renders/Nightlight/2BHKAPassage1NL.webp',
      mobileUrl: '/360renders/Daylight/Mobile/Mob2BHKAPassage1DL.webp',
      mobileNightUrl: '/360renders/Nightlight/Mobile/Mob2BHKAPassage1NL.webp',
      hotspots: [
        {
          position: [-27, -95, -112],
          mobilePosition: [-66.071, -199.961, -210.682],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 0],
          title: 'Passage',
          target: '/2bhk/kitchen',
          type: 'FreeRoam',
        },
        {
          position: [-27, -95, -110],
          mobilePosition: [-56.782, -169.703, -229.191],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 3.2],
          title: 'Passage',
          target: '/2bhk/kitchen',
          type: 'Arrow',
        },
        {
          position: [-126, -95, 1],
          mobilePosition: [-232.033, -170.697, 4.003],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 1.6],
          title: 'Passage',
          target: '/2bhk/passage2',
          type: 'Arrow',
        },
        {
          position: [383, -152, -153],
          mobilePosition: [252.584, -109.238, -100.396],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Passage',
          target: '/2bhk/livingroom2',
          type: 'FreeRoam',
        },
        {
          position: [-719, -234, 81],
          mobilePosition: [-277.9, -90.512, 27.589],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 1.6],
          title: 'Passage',
          target: '/2bhk/bedroom',
          type: 'FreeRoam',
        },
        //Passage Info
        {
          position: [-265.659, 25.294, 4.569],
          mobilePosition: [-265.659, 25.294, 4.569],
          width: 65,
          height: 90,
          title: 'PASSAGE',
          dimensions: `3'3" X 9'2"`,
          target: '/2bhk/livingroom',
          type: 'Info',
        },
        //{ position: [120, -78, 3], mobilePosition: [1.6, 1.8, 0], scale: [5,5,5], rotation:[1.6,0,1.6], title: "Passage",target: '/livingroom2', type:'Arrow'  },
        {
          position: [-51, -234, 212],
          mobilePosition: [-40.117, -222.4, 184.613],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 0],
          title: 'Bathroom',
          target: '/2bhk/toilet',
          type: 'FreeRoam',
        },
        {
          position: [-431, -234, 4],
          mobilePosition: [-260.97, -138.375, 1.362],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 1.6],
          title: 'Bathroom',
          target: '/2bhk/passage2',
          type: 'FreeRoam',
        },
        //{ position: [-907, -30, -223], mobilePosition: [1.6, 1.8, 0], width:60, height:60, title: "Passage",target: '/masterbedroom1', type:'View'  }
      ],
      camera: { position: [0, 0, 0], lookAt: [11.961, 2.335, 5.2] },
      mapMarkerPosition: {
        right: -10.2,
        bottom: 21.8,
        mobileX: -15.2,
        mobileY: 35,
      },
    },
  },

  getObject: (location) => {
    let targ = get().objects[location]
    if (targ != null) {
      return targ
    } else return null
  },
}))

export const useMaster3bhk = create((set, get) => ({
  objects: {
    '/3bhk': {
      hotspots: [],
    },
    '/3bhk/home': {
      title: '3BHK - B',
      tower: 'T1 - 01',
      hotspots: [
        {
          position: [0, 4, -5.5],
          mobilePosition: [-1, 6, 5],
          scale: 1,
          title: 'LivingRoom',
          target: '/3bhk/livingroom1',
          svg: <LivingRoomHS opacity={0.75} />,
          svgHover: <LivingRoomHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [2.5, 4, -3],
          mobilePosition: [4, 6, -5],
          scale: 1,
          title: 'Kitchen',
          target: '/3bhk/kitchen',
          svg: <KitchenHS opacity={0.75} />,
          svgHover: <KitchenHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [2.5, 4, -0.5],
          mobilePosition: [4, 6, -1.5],
          scale: 1,
          title: 'Bedroom',
          target: '/3bhk/bedroom3',
          svg: <BedroomHS opacity={0.75} />,
          svgHover: <BedroomHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [2.5, 4, 5],
          mobilePosition: [4, 6, -1.5],
          scale: 1,
          title: 'Bedroom',
          target: '/3bhk/bedroom2',
          svg: <BedroomHS opacity={0.75} />,
          svgHover: <BedroomHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [2.5, 4, 2.5],
          mobilePosition: [4, 6, -1.5],
          width: 75,
          height: 95,
          title: 'Bathroom',
          target: '/3bhk/bathroom2',
          svg: <BathroomHS opacity={0.75} />,
          svgHover: <BathroomHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [-1, 4, 5],
          mobilePosition: [4, 6, -5],
          scale: 1,
          title: 'MasterBedroom',
          target: '/3bhk/masterbedroom',
          svgHover: <MasterBedroomHS opacity={1} />,
          svg: <MasterBedroomHS opacity={0.75} />,
          type: 'apartmentPreview',
        },
        {
          position: [-1.5, 4, 1],
          mobilePosition: [4, 6, -5],
          scale: 1,
          title: 'MasterBathroom',
          target: '/3bhk/mbathroom',
          svg: <BathroomHS opacity={0.75} />,
          svgHover: <BathroomHS opacity={1} />,
          type: 'apartmentPreview',
        },
        {
          position: [-1.5, 4, -1.5],
          mobilePosition: [-3, 6, 1.5],
          scale: 1,
          title: 'Toilet',
          target: '/3bhk/bathroom3',
          svg: <BathroomHS opacity={0.75} />,
          svgHover: <BathroomHS opacity={1} />,
          type: 'apartmentPreview',
        },
      ],
    },
    '/3bhk/livingroom1': {
      title: 'Living + Dining',
      url: '/360renders/ThreeBHK/Daylight/3BHKBLivRoom1DL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBLivRoom1NL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBLivRoom1DL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBLivRoom1NL.webp',
      hotspots: [
        {
          position: [-276.531, -86.655, 53.351],
          mobilePosition: [-276.531, -86.655, 53.351],
          mobileScale: [30, 30, 30],
          scale: [10.0, 10.0, 10.0],
          rotation: [1.6, 0, 2],
          title: 'Living Room',
          target: '/3bhk/livingroom2',
          type: 'FreeRoam',
        },
        {
          position: [-274.12, 4.745, 106.309],
          mobilePosition: [-274.12, 4.745, 106.309],
          width: 75,
          height: 100,
          title: 'LIVING',
          dimensions: `21'8" X 10'2"`,
          target: '/livingroom1',
          type: 'Info',
        },
        {
          position: [-241.059, 15.543, -169.325],
          mobilePosition: [-241.059, 15.543, -169.325],
          width: 75,
          height: 100,
          title: 'DINING',
          dimensions: `11'6" X 6'7"`,
          target: '/livingroom1',
          type: 'Info',
        },
        {
          position: [-276.531, -86.655, 53.351], //LivingRoom2
          mobilePosition: [-276.531, -86.655, 53.351],
          mobileScale: [20, 20, 20],
          scale: [15, 15, 15],
          rotation: [1.6, 0, 2.1],
          title: 'Living Room',
          target: '/livingroom1',
          type: 'Arrow',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.098, 1.219, 1.593] },
      mapMarkerPosition: {
        right: -0.8,
        bottom: 1,
        mobileX: -2.5,
        mobileY: 56,
        // position: [81,0]
      },
    },
    '/3bhk/livingroom2': {
      title: 'Living + Dining',
      url: '/360renders/ThreeBHK/Daylight/3BHKBLivRoom2DL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBLivRoom2NL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBLivRoom2DL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBLivRoom2NL.webp',
      hotspots: [
        {
          position: [155.253, -104.601, 230.804],
          mobilePosition: [155.253, -104.601, 230.804],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 2],
          title: 'LivingRoom2',
          target: '/3bhk/livingroom1',
          type: 'FreeRoam',
        },
        {
          position: [19.231, -156.339, -249.02], //Passage1
          mobilePosition: [19.231, -156.339, -249.02],
          mobileScale: [20, 20, 20],
          scale: [12.0, 12.0, 12.0],
          rotation: [1.6, 0, 3.5],
          title: 'LivingRoom2',
          target: '/3bhk/passage1',
          type: 'Arrow',
        },
        {
          position: [70.439, -111.141, -266.108],
          mobilePosition: [70.439, -111.141, -266.108],
          mobileScale: [30, 30, 30],
          scale: [10, 10, 10],
          rotation: [1.6, 0, 0.4],
          title: 'Passage1',
          target: '/3bhk/passage1',
          type: 'FreeRoam',
        },
        {
          position: [-278.955, -0.026, -96.359],
          mobilePosition: [-278.955, -0.026, -96.359],
          width: 75,
          height: 100,
          ttitle: 'LIVING',
          dimensions: `21'8" X 10'2"`,
          target: '/3bhk/livingroom1',
          type: 'Info',
        },
        {
          position: [289.013, 7.964, 33.304],
          mobilePosition: [289.013, 7.964, 33.304],
          width: 75,
          height: 100,
          title: 'DINING',
          dimensions: `11'6" X 6'7"`,
          target: '/3bhk/livingroom1',
          type: 'Info',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.145, 0.279, 1.64] },
      mapMarkerPosition: {
        right: -4,
        bottom: 12,
        mobileX: -4.5,
        mobileY: 38,
      },
    },
    '/3bhk/passage1': {
      title: 'Passage',
      url: '/360renders/ThreeBHK/Daylight/3BHKBPassage1DL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBPassage1NL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBPassage1DL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBPassage1NL.webp',
      hotspots: [
        // FreeRoam Kitchen
        {
          position: [-81.288, -208.125, 190.7],
          mobilePosition: [-81.288, -208.125, 190.7],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 0],
          title: 'Kitchen',
          target: '/3bhk/kitchen',
          type: 'FreeRoam',
        },
        // Arrow Kitchen
        {
          position: [-81.288, -208.125, 190.7], //Kitchen
          mobilePosition: [-81.288, -208.125, 190.7],
          mobileScale: [20, 20, 20],
          scale: [15, 15, 15],
          rotation: [1.6, 0, 0],
          title: 'Kitchen',
          target: '/3bhk/kitchen',
          type: 'Arrow',
        },
        // FreeRoam LivingRoom2
        {
          position: [272.052, -90.787, -17.983],
          mobilePosition: [272.052, -90.787, -17.983],
          mobileScale: [30, 30, 30],
          scale: [10.0, 10.0, 10.0],
          rotation: [1.6, 0, 1.6],
          title: 'Living Room',
          target: '/3bhk/livingroom2',
          type: 'FreeRoam',
        },
        // FreeRoam Passage2
        {
          position: [-281.534, -84.659, -0.53],
          mobilePosition: [-281.534, -84.659, -0.53],
          mobileScale: [30, 30, 30],
          scale: [7.5, 7.5, 7.5],
          rotation: [1.6, 0, 1.6],
          title: 'Passage2',
          target: '/3bhk/passage2',
          type: 'FreeRoam',
        },
        // FreeRoam Bathroom3
        {
          position: [-267.299, -117.083, -40.592],
          mobilePosition: [-267.299, -117.083, -40.592],
          mobileScale: [30, 30, 30],
          scale: [9, 9, 9],
          rotation: [1.6, 0, 1.6],
          title: 'Bathroom3',
          target: '/3bhk/bathroom3',
          type: 'FreeRoam',
        },
        // Info Passage
        {
          position: [-281.534, 11.181, -2.347],
          mobilePosition: [-281.534, 11.181, -2.347],
          width: 75,
          height: 100,
          title: 'PASSAGE',
          dimensions: `2'11" X 16'1"`,
          target: '/3bhk/livingroom1',
          type: 'Info',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [12.432, 2.402, -3.903] },
      mapMarkerPosition: {
        right: -8.4,
        bottom: 14.2,
        mobileX: -15.2,
        mobileY: 35,
      },
    },
    '/3bhk/kitchen': {
      title: 'Kitchen',
      url: '/360renders/ThreeBHK/Daylight/3BHKBKitchenDL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBKitchenNL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBKitchenDL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBKitchenNL.webp',
      hotspots: [
        // freeRoam Passage1
        {
          position: [176.528, -231.896, 8.396],
          mobilePosition: [176.528, -231.896, 8.396],
          mobileScale: [25, 25, 25],
          scale: [15, 15, 15],
          rotation: [1.6, 0, 1.6],
          title: 'Passage1',
          target: '/3bhk/passage1',
          type: 'FreeRoam',
        },
        // Arrow Passage 1
        {
          position: [176.528, -231.896, 8.396], //Bathroom3
          mobilePosition: [176.528, -231.896, 8.396],
          mobileScale: [20, 20, 20],
          scale: [15.0, 15.0, 15.0],
          rotation: [1.6, 0, 3.2],
          title: 'Kitchen',
          target: '/3bhk/passage1',
          type: 'Arrow',
        },
        // Info Kitchen
        {
          position: [-234.263, -26.384, 176.682],
          mobilePosition: [-234.263, -26.384, 176.682],
          width: 75,
          height: 100,
          title: 'KITCHEN',
          dimensions: `9'2" X 7'7"`,
          target: '/3bhk/kitchen',
          type: 'Info',
        },
        // Info Utility
        {
          position: [-293.666, -11.177, 7.319],
          mobilePosition: [-293.666, -11.177, 7.319],
          width: 75,
          height: 100,
          title: 'UTLITY',
          dimensions: `3'11" X 5'3"`,
          target: '/3bhk/kitchen',
          type: 'Info',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.214, -0.252, 0.947] },
      mapMarkerPosition: {
        right: -8.4,
        bottom: 19.5,
        mobileX: -16.5,
        mobileY: 20,
      },
    },
    '/3bhk/bathroom3': {
      title: 'Toilet - 2',
      url: '/360renders/ThreeBHK/Daylight/3BHKBToilet3DL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBToilet3NL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBToilet3DL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBToilet3NL.webp',
      hotspots: [
        // Info  Bathroom3
        {
          position: [-295.033, 20.908, 18.15],
          mobilePosition: [-295.033, 20.908, 18.15],
          width: 75,
          height: 100,
          title: 'TOILET - 2',
          dimensions: `8'2" X 4'9"`,
          target: '/3bhk/bathroom3',
          type: 'Info',
        },
        // FreeRoam Passage 1
        {
          position: [156.697, -245.097, 8.383],
          mobilePosition: [156.697, -245.097, 8.383],
          mobileScale: [30, 30, 30],
          scale: [20.0, 20.0, 20.0],
          rotation: [1.6, 0, 1.6],
          title: 'Bathroom 3',
          target: '/3bhk/passage1',
          type: 'FreeRoam',
        },
        // Arrow Passage 1
        {
          position: [156.697, -245.097, 8.383], //Bedroom3
          mobilePosition: [156.697, -245.097, 8.383],
          mobileScale: [20, 20, 20],
          scale: [15.0, 15.0, 15.0],
          rotation: [1.6, 0, 0],
          title: 'Bathroom 3',
          target: '/3bhk/bedroom3',
          type: 'Arrow',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [9.491, 4.992, 7.782] },
      mapMarkerPosition: {
        right: -11.8,
        bottom: 10,
        mobileX: -28,
        mobileY: 24,
      },
    },
    '/3bhk/passage2': {
      title: 'Passage',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBPassage2NL.webp',
      url: '/360renders/ThreeBHK/Daylight/3BHKBPassage2DL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBPassage1DL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBPassage1NL.webp',
      hotspots: [
        // FreeRoam Bedroom 3
        {
          position: [-151.778, -218.204, 122.139],
          mobilePosition: [-151.778, -218.204, 122.139],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 1.6],
          title: 'Bedroom3',
          target: '/3bhk/bedroom3',
          type: 'FreeRoam',
        },
        // FreeRoam Bedroom 2
        {
          position: [-258.342, -128.086, 67.621],
          mobilePosition: [-258.342, -128.086, 67.621],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Bedroom2',
          target: '/3bhk/bedroom2',
          type: 'FreeRoam',
        },
        //Free Roam MasterBedroom
        {
          position: [-284.341, -75.417, -27.959],
          mobilePosition: [-284.341, -75.417, -27.959],
          mobileScale: [30, 30, 30],
          scale: [7.5, 7.5, 7.5],
          rotation: [1.6, 0, 1.6],
          title: 'MasterBedroom',
          target: '/3bhk/masterbedroom',
          type: 'FreeRoam',
        },
        // FreeRoam Passage 1
        {
          position: [275.488, -81.548, 2.78],
          mobilePosition: [275.488, -81.548, 2.78],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Passage1',
          target: '/3bhk/passage1',
          type: 'FreeRoam',
        },
        // FreeRoam Bathroom3
        {
          position: [237.996, -160.988, -42.26],
          mobilePosition: [237.996, -160.988, -42.26],
          mobileScale: [30, 30, 30],
          scale: [16, 16, 16],
          rotation: [1.6, 0, 1.6],
          title: 'Bathroom3',
          target: '/3bhk/bathroom3',
          type: 'FreeRoam',
        },
        // Arrow Bedroom 3
        {
          position: [-151.778, -218.204, 122.139],
          mobilePosition: [-151.778, -218.204, 122.139],
          mobileScale: [20, 20, 20],
          scale: [15.0, 15.0, 15.0],
          rotation: [1.6, 0, 0],
          title: 'Passage 2',
          target: '/3bhk/bedroom3',
          type: 'Arrow',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [11.295, 3.153, -6.169] },
      mapMarkerPosition: {
        right: -14,
        bottom: 14.2,
        mobileX: -4.5,
        mobileY: 38,
      },
    },
    '/3bhk/bedroom3': {
      title: 'Bedroom - 2',
      url: '/360renders/ThreeBHK/Daylight/3BHKBBedroom2DL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBBedroom2NL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBBedroom2DL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBBedroom2NL.webp',
      hotspots: [
        // FreeRoam Passage 2
        {
          position: [181.973, -217.027, 70.683],
          mobilePosition: [181.973, -217.027, 70.683],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 2.2],
          title: 'Bedroom 3',
          target: '/3bhk/passage2',
          type: 'FreeRoam',
        },
        // Arrow Bedroom 2
        {
          position: [181.973, -217.027, 70.683], //Bedroom2
          mobilePosition: [181.973, -217.027, 70.683],
          mobileScale: [20, 20, 20],
          scale: [15, 15, 15],
          rotation: [1.6, 0, 3.8],
          title: 'Bedroom 3',
          target: '/3bhk/bedroom2',
          type: 'Arrow',
        },
        // Info Bedroom 3
        {
          position: [-286.703, 5.661, 64.976],
          mobilePosition: [-286.703, 5.661, 64.976],
          width: 75,
          height: 100,
          title: 'BEDROOM - 2',
          dimensions: `13'9" X 10'0"`,
          target: '/3bhk/bedroom3',
          type: 'Info',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [12.323, 0.094, 4.867] },
      mapMarkerPosition: {
        right: -15.8,
        bottom: 19,
        mobileX: -22.3,
        mobileY: 34.5,
      },
    },
    '/3bhk/bedroom2': {
      title: 'Bedroom - 1',
      url: '/360renders/ThreeBHK/Daylight/3BHKBBedroom1DL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBBedroom1NL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBBedroom1DL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBBedroom1NL.webp',
      hotspots: [
        // FreeRoam Bathroom 2
        {
          position: [128.854, -203.887, 170.237],
          mobilePosition: [128.854, -203.887, 170.237],
          mobileScale: [30, 30, 30],
          scale: [20, 20, 20],
          rotation: [1.6, 0, 2.2],
          title: 'Bedroom 2',
          target: '/3bhk/bathroom2',
          type: 'FreeRoam',
        },
        // FreeRoam Passage 2
        {
          position: [268.883, -104.05, 64.723],
          mobilePosition: [268.883, -104.05, 64.723],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 2.2],
          title: 'Passage 2',
          target: '/3bhk/passage2',
          type: 'FreeRoam',
        },
        // Arrow Bathroom 2
        {
          position: [122.228, -250.078, 99.16], //Bathroom 2
          mobilePosition: [122.228, -250.078, 99.16],
          mobileScale: [20, 20, 20],
          scale: [15.0, 15.0, 15.0],
          rotation: [1.6, 0, -0.2],
          title: 'Bedroom 2',
          target: '/3bhk/bathroom2',
          type: 'Arrow',
        },
        // Info Bedroom 2
        {
          position: [-284.791, 36.462, -68.557],
          mobilePosition: [-284.791, 36.462, -68.557],
          width: 75,
          height: 100,
          title: 'BEDROOM - 1',
          dimensions: `13'9" X 10'0"`,
          target: '/3bhk/bedroom2',
          type: 'Info',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.111, -0.089, 1.914] },
      mapMarkerPosition: {
        right: -21.5,
        bottom: 20.5,
        mobileX: -29.8,
        mobileY: 37,
      },
    },
    '/3bhk/bathroom2': {
      title: 'Toilet - 1',
      url: '/360renders/ThreeBHK/Daylight/3BHKBToilet2DL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBToilet2NL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBToilet2DL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBToilet2NL',
      hotspots: [
        // info Bathroom 2
        {
          position: [-286.518, -29.327, 6.763],
          mobilePosition: [-286.518, -29.327, 6.763],
          width: 75,
          height: 100,
          title: 'TOILET - 1',
          dimensions: `7'10" X 5'3"`,
          target: '/3bhk/bathroom2',
          type: 'Info',
        },
        // FreeRoam Passage 2
        {
          position: [225.754, -166.705, 92.427],
          mobilePosition: [225.754, -166.705, 92.427],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'Bathroom 2',
          target: '/3bhk/passage2',
          type: 'FreeRoam',
        },
        // FreeRoam Bedroom2
        {
          position: [148.93, -186.671, -168.703],
          mobilePosition: [148.93, -186.671, -168.703],
          mobileScale: [25, 25, 25],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'Bathroom 2',
          target: '/3bhk/bedroom2',
          type: 'FreeRoam',
        },
        // Arrow MasterBedroom
        {
          position: [164.701, -242.177, 33.52], //MasterBedroom
          mobilePosition: [164.701, -242.177, 33.52],
          mobileScale: [20, 20, 20],
          scale: [15, 15, 15],
          rotation: [1.6, 0, -1.0],
          title: 'Bathroom 2',
          target: '/3bhk/masterbedroom',
          type: 'Arrow',
        },
      ],

      camera: { position: [0, 0, 0], lookAt: [12.432, 2.402, -3.903] },
      mapMarkerPosition: {
        right: -19.5,
        bottom: 24,
        mobileX: -15.9,
        mobileY: 42.8,
      },
    },
    '/3bhk/masterbedroom': {
      title: 'Master Bedroom',
      url: '/360renders/ThreeBHK/Daylight/3BHKBMBedroomDL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBMBedroomNL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBMBedroomDL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBMBedroomNL.webp',
      hotspots: [
        //FreeRoam MasterBathroom
        {
          position: [43.245, -185.3, -225.399],
          mobilePosition: [43.245, -185.3, -225.399],
          mobileScale: [30, 30, 30],
          scale: [18, 18, 18],
          rotation: [1.6, 0, 1.6],
          title: 'Master Bedroom',
          target: '/3bhk/mbathroom',
          type: 'FreeRoam',
        },
        //FreeRoam Bedroom 2
        {
          position: [241.526, -165.948, -33.607],
          mobilePosition: [241.526, -165.948, -33.607],
          mobileScale: [30, 30, 30],
          scale: [14, 14, 14],
          rotation: [1.6, 0, 1.6],
          title: 'Master Bedroom',
          target: '/3bhk/bedroom2',
          type: 'FreeRoam',
        },
        //FreeRoam Bedroom 3
        {
          position: [253.952, -105.552, -109.464],
          mobilePosition: [253.952, -105.552, -109.464],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Master Bedroom',
          target: '/3bhk/bedroom3',
          type: 'FreeRoam',
        },
        //FreeRoam Passage 2
        {
          position: [230.983, -76.596, -166.533],
          mobilePosition: [230.983, -76.596, -166.533],
          mobileScale: [30, 30, 30],
          scale: [12, 12, 12],
          rotation: [1.6, 0, 1.6],
          title: 'Master Bedroom',
          target: '/3bhk/passage2',
          type: 'FreeRoam',
        },
        //Info Master Bedroom
        {
          position: [-276.448, -12.328, 96.682],
          mobilePosition: [-276.448, -12.328, 96.682],
          width: 75,
          height: 100,
          title: 'M.BEDROOM',
          dimensions: `11'6" X 11'10"`,
          target: '/3bhk/masterbedroom',
          type: 'Info',
        },
        //Arrow master bathroom
        {
          position: [-45.458, -230.364, -176.318], //MasterBathroom
          mobilePosition: [-45.458, -230.364, -176.318],
          mobileScale: [20, 20, 20],
          scale: [15, 15, 15],
          rotation: [1.6, 0, -2.4],
          title: 'Master Bedroom',
          target: '/3bhk/mbathroom',
          type: 'Arrow',
        },
      ],
      camera: { position: [0, 0, 0], lookAt: [13.103, 1.898, -0.511] },
      mapMarkerPosition: {
        right: -20.5,
        bottom: 13,
        mobileX: -25,
        mobileY: 24,
      },
    },
    '/3bhk/mbathroom': {
      title: 'Master Toilet',
      url: '/360renders/ThreeBHK/Daylight/3BHKBMToiletDL.webp',
      nightUrl: '/360renders/ThreeBHK/Nightlight/3BHKBMToiletNL.webp',
      mobileUrl: '/360renders/ThreeBHK/Daylight/Mobile/Mob3BHKBMToiletDL.webp',
      mobileNightUrl:
        '/360renders/ThreeBHK/Nightlight/Mobile/Mob3BHKBMToiletNL.webp ',
      hotspots: [
        //Info MasterBathroom
        {
          position: [-287.79, 11.8, 7.776],
          mobilePosition: [-287.79, 11.8, 7.776],
          width: 75,
          height: 100,
          title: 'M.TOILET',
          dimensions: `4'11" X 8'0"`,
          target: '/3bhk/mbathroom',
          type: 'Info',
        },
        //FreeRoam Master Bedroom
        {
          position: [246.9, -142.732, -52.731],
          mobilePosition: [246.9, -142.732, -52.731],
          mobileScale: [30, 30, 30],
          scale: [15, 15, 15],
          rotation: [1.6, 0, 1.6],
          title: 'Master Bathroom',
          target: '/3bhk/masterbedroom',
          type: 'FreeRoam',
        },
      ],

      camera: { position: [0, 0, 0], lookAt: [12.432, 2.402, -3.903] },
      mapMarkerPosition: {
        right: -18,
        bottom: 9.5,
        mobileX: -24,
        mobileY: 18,
      },
    },
  },

  getObject3bhk: (location) => {
    let targ = get().objects[location]
    if (targ != null) {
      return targ
    } else return null
  },
}))

export const useBlur = create((set) => ({
  blur: false,
  setBlur: (newState) => set((state) => (state.blur = newState)),
}))

export const usePanoramaLoading = create((set) => ({
  isLoading: false,
  setLoading: (loading) => set((state) => (state.loading = loading)),
}))

export const useEverythingLoaded = create((set) => ({
  everythingLoaded: false, //Default:false
  agreementAccepted: false, //Default:false
  setAgreementAccepted: (bool) => set((state) => ({ agreementAccepted: bool })),
  setEverythingLoaded: (bool) => set((state) => ({ everythingLoaded: true })),
}))

const flattenLocations = (loc) => {
  // Flatten and add era field to location objects
  let flattenedArray = []
  for (let idx in loc) {
    if (isMobile) {
      if (loc[idx].url != null) {
        flattenedArray = [
          ...flattenedArray,
          loc[idx].mobileUrl,
          loc[idx].mobileNightUrl,
        ]
      }
    } else {
      if (loc[idx].url != null) {
        flattenedArray = [...flattenedArray, loc[idx].url, loc[idx].nightUrl]
      }
    }
  }
  // flattenedArray = flattenedArray.map((x,idx) => ({...x,locationId:idx}))
  return flattenedArray
}

export const useMode = create((set) => ({
  name: null,
  setName: (mode) => {
    set((state) => ({
      name: mode,
    }))
    useLoader.getState().setItems()
    usePanoramaViewer.getState().setlocations()
  },
}))

function setMode() {
  if (useMode.getState().name !== null) {
    if (useMode.getState().name === '3bhk') {
      return useMaster3bhk.getState().objects
    } else {
      return useMaster.getState().objects
    }
  } else {
    return useMaster.getState().objects
  }
}

// export const flattenedLocations = flattenLocations(setMode())

export const useLoader = create((set) => ({
  loading: false,
  items: flattenLocations(setMode()),
  setItems: () => set(() => ({ items: flattenLocations(setMode()) })),
  loadedModel: null,
  setLoadedModel: (model) => set((state) => ({ loadedModel: model })),
  reportDone: (name) =>
    set((state) => {
      return { ...state, items: state.items.filter((x) => x !== name) }
    }),

  // items:state.items.filter(x => x.name !== name)
  // return {...state, items:state.items.filter(x => x.name !== name)}

  addItems: (newItems) =>
    set((state) => (state.items = [...state.items, ...newItems])),
}))

export const usePanoramaViewer = create((set) => ({
  lastLocation: {},
  allLocations: flattenLocations(setMode()),
  currentLocation: flattenLocations(setMode())[
    flattenLocations(setMode()).length - 1
  ],
  setCurrentLocation: (loc) =>
    set((state) => {
      // if(!state.currentLocation.loading)
      state.lastLocation = state.currentLocation
      state.currentLocation = loc
    }),
  // setDisplay : (flag) => set((state)=>state.displayLocation=flag),
  setLastLocation: (loc) =>
    set((state) => {
      state.lastLocation = loc
    }),
  setlocations: () =>
    set(() => ({
      allLocations: flattenLocations(setMode()),
      currentLocation: flattenLocations(setMode())[
        flattenLocations(setMode()).length - 1
      ],
    })),
}))
