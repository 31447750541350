import { StyleSheet, css } from 'aphrodite'
import React from 'react'

function UserPromptContainerFlipped() {
  return (
    <svg
      viewBox="0 0 128 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={css(styles.rotate)}
    >
      <circle
        opacity="0.9"
        cx="4.51534"
        cy="44.5769"
        r="4.51534"
        fill="white"
        fillOpacity="0.9"
      />
      <rect
        x="7"
        y="0.908203"
        width="121"
        height="41"
        rx="4"
        fill="white"
        fillOpacity="0.9"
      />
    </svg>
  )
}

export default UserPromptContainerFlipped

const styles = StyleSheet.create({
  rotate: {
    transform: 'scaleX(-1)',
  },
})
