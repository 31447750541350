import './App.css'
import { Canvas } from '@react-three/fiber'
import LoadingScreen from './components/core/LoadingScreen'
import { a as web } from '@react-spring/web'
import ApartmentPreviewBackground from './components/context/ui/ApartmentPreviewBackground'
import HUDManager from './components/core/HUDManager'
import LoadingManager from './components/core/LoadingManager'
import { CameraController } from './components/core/CameraController'
import { Suspense } from 'react'
import PanoramaManager from './components/core/PanoramaManager'
import { Leva } from 'leva'
import Controllables from './components/helpers/Controllables'
import { useEffect, useState } from 'react'
// import CameraValues from './components/helpers/CameraValues'
import { useLocation } from 'wouter'
import { useLoader, useMode, usePanoramaViewer } from './store'
import { AdaptiveDpr, useDetectGPU } from '@react-three/drei'
import ReactTimer from './components/core/ReactTimer'
import CameraLoadingScreen from './components/context/CameraLoadingScreen'

function App() {
  const [mode, setMode] = useState(null)
  const [location, setLocation] = useLocation()
  const { setName } = useMode()
  const { setItems } = useLoader()
  const { setlocations } = usePanoramaViewer()
  const GPU = useDetectGPU()

  useEffect(() => {
    if (location.includes('2bhk')) {
      setName('2bhk')
      setItems()
      setlocations()
      setMode('2bhk')
    } else if (location.includes('3bhk')) {
      setName('3bhk')
      setMode('3bhk')
    } else {
      setName('2bhk')
      setItems()
      setlocations()
      setMode('2bhk')
      setLocation('/2bhk')
    }
  }, [])

  if (mode !== null) {
    return (
      <web.main>
        {/* <Leva
          flat // default = false,  true removes border radius and shadow
          oneLineLabels // default = false, alternative layout for labels, with labels and fields on separate rows
          collapsed // default = false, when true the GUI is collpased
          hidden={false}
        /> */}
        <ApartmentPreviewBackground />
        {GPU !== null && (
          <Canvas
            dpr={[1, 1.5]}
            performance={{ min: 0.8, max: 1.1 }}
            colorManagement
            flat
          >
            <AdaptiveDpr />
            {/* <axesHelper args={[300, 300]} /> */}
            <ambientLight />
            <CameraController
              dampingState={
                GPU.tier === 0 ||
                GPU.isMobile ||
                GPU.tier == null ||
                GPU.isMobile == null
                  ? false
                  : true
              }
            />
            <LoadingManager />
            {/* <Controllables /> */}
            <Suspense fallback={null}>
              <PanoramaManager />
            </Suspense>
            {/* <ReactTimer /> */}
          </Canvas>
        )}

        {/* <CameraValues /> */}
        {/* <CameraLoadingScreen /> */}
        <LoadingScreen />
        <HUDManager />
      </web.main>
    )
  } else return null
}

export default App

// https://codepen.io/zebateira/pen/VvqJwm
