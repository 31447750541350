import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, useLocation } from 'wouter'
import {
  // useEverythingLoaded,
  useMaster,
  useMaster3bhk,
  // useMode,
  // usePanoramaViewer,
} from '../../store'
import Hotspot from './Hotspot'
import HotspotSpawn from './HotspotSpawn'

export default function HotspotManager(props) {
  const [location] = useLocation()
  const { getObject } = useMaster()
  const { getObject3bhk } = useMaster3bhk()
  const [currentObject, setObject] = useState({ hotspots: [] })
  const [hidden, setHidden] = useState(false)
  // const { name } = useMode()

  useEffect(() => {
    setHidden(false)
    if (location.includes('2bhk')) {
      setObject(getObject(location))
    } else if (location.includes('3bhk')) {
      setObject(getObject3bhk(location))
    }
    setTimeout(() => {
      setHidden(true)
    }, 1500)
  }, [location])

  // useEffect(() => {}, [props.visibility])

  return (
    <>
      {currentObject != null && (
        <>
          {currentObject.hotspots.map((hotspot, index) => {
            return (
              <Hotspot
                key={index}
                type={hotspot.type}
                hidden={hidden && props.visibility}
                position={
                  !isMobile
                    ? hotspot.position
                    : hotspot.mobilePosition != null
                    ? hotspot.mobilePosition
                    : hotspot.position
                }
                scale={
                  !isMobile
                    ? hotspot.scale
                    : hotspot.mobileScale != null
                    ? hotspot.mobileScale
                    : hotspot.scale
                }
                rotation={hotspot.rotation}
              >
                <Link href={hotspot.target} style={{ pointerEvents: 'auto' }}>
                  <HotspotSpawn
                    svgHover={
                      hotspot.svgHover != null ? hotspot.svgHover : null
                    }
                    svg={hotspot.svg != null ? hotspot.svg : null}
                    object={hotspot}
                    visibility={props.visibility}
                  />
                </Link>
              </Hotspot>
            )
          })}
        </>
      )}
    </>
  )
}
