import React from 'react'

function Home(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        stroke="white"
        strokeOpacity="0.75"
      />
      <rect
        x="3"
        y="3"
        width="42"
        height="42"
        rx="21"
        fill="white"
        fillOpacity={props.opacity}
      />
      <path
        d="M35.2053 23.2431C35.1066 23.2437 35.0088 23.2247 34.9174 23.1874C34.826 23.1501 34.7429 23.0951 34.6728 23.0256L23.9553 12.3006L13.2378 23.0256C13.0944 23.1485 12.9098 23.2127 12.7211 23.2054C12.5323 23.1981 12.3532 23.1198 12.2197 22.9863C12.0861 22.8527 12.0079 22.6736 12.0006 22.4849C11.9933 22.2961 12.0575 22.1116 12.1803 21.9681L23.4303 10.7181C23.5709 10.5784 23.761 10.5 23.9591 10.5C24.1572 10.5 24.3473 10.5784 24.4878 10.7181L35.7378 21.9681C35.841 22.0734 35.9109 22.2068 35.9388 22.3515C35.9666 22.4963 35.9511 22.6461 35.8943 22.7821C35.8375 22.9182 35.7418 23.0345 35.6193 23.1165C35.4968 23.1984 35.3528 23.2425 35.2053 23.2431Z"
        fill="#850C70"
      />
      <path
        d="M23.9556 14.8354L14.9556 23.8654V32.9929C14.9556 33.3908 15.1136 33.7723 15.3949 34.0536C15.6762 34.3349 16.0577 34.4929 16.4556 34.4929H21.7056V26.9929H26.2056V34.4929H31.4556C31.8534 34.4929 32.2349 34.3349 32.5162 34.0536C32.7975 33.7723 32.9556 33.3908 32.9556 32.9929V23.8129L23.9556 14.8354Z"
        fill="#850C70"
      />
    </svg>
  )
}

export default Home
