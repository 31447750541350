import React from 'react'

function NightLighting(props) {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="47"
        height="47"
        rx="23.5"
        stroke="white"
        strokeOpacity="0.75"
      />
      <rect
        x="3"
        y="3"
        width="42"
        height="42"
        rx="21"
        fill="white"
        fillOpacity={props.opacity}
      />
      <path
        d="M21.8002 12.4067C21.9433 12.5807 22.031 12.7936 22.0519 13.0179C22.0729 13.2423 22.026 13.4677 21.9175 13.6652C21.0695 15.222 20.6267 16.9671 20.6297 18.7399C20.6297 24.6375 25.4375 29.413 31.363 29.413C32.1359 29.413 32.8883 29.3324 33.6114 29.1784C33.8331 29.1304 34.064 29.1487 34.2752 29.2312C34.4865 29.3136 34.6688 29.4564 34.7994 29.6418C34.9373 29.8346 35.0076 30.0676 34.9994 30.3044C34.9911 30.5413 34.9049 30.7688 34.754 30.9516C33.6036 32.3647 32.1524 33.5032 30.5062 34.2842C28.86 35.0652 27.0602 35.469 25.2381 35.4661C18.4766 35.4661 13 30.0188 13 23.3072C13 18.2559 16.1006 13.9233 20.5153 12.087C20.7352 11.9941 20.9794 11.975 21.2111 12.0326C21.4427 12.0903 21.6494 12.2216 21.8002 12.4067Z"
        fill="#850C70"
      />
    </svg>
  )
}

export default NightLighting
