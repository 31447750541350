import React, { Suspense, useEffect, useState } from 'react'
import { useLocation } from 'wouter'
import { useApartmentPreview, useEverythingLoaded, useMode } from '../../store'
import ApartmentPreview from '../context/ApartmentPreview'
import UserPrompts1 from './UserPrompts1'
import UserPrompts3D from './UserPrompts3D'

export default function LoadingManager() {
  const [previewScreen, setPreviewScreen] = useState(false)
  const [previewModelState, setPreviewModelState] = useState(false)
  const [location, setLocation] = useLocation()
  // const { setLoadedModel } = useLoader()

  useEffect(() => {
    // setLoadedModel('PreviewModel')
    const unsub = useEverythingLoaded.subscribe(
      (paw, previousPaw) => {
        setPreviewScreen(paw)
      },
      (state) => state.agreementAccepted
    )
    const unsub1 = useApartmentPreview.subscribe(
      (paw, previousPaw) => {
        setPreviewModelState(paw)
      },
      (state) => state.apartmentPreview
    )
    return function cleanup() {
      unsub()
      unsub1()
    }
  }, [])

  return (
    <>
      <Suspense fallback={null}>
        <ApartmentPreview previewScreenVisibility={previewModelState} />
      </Suspense>

      {location !== '/' &&
        location !== `/${useMode.getState().name}` &&
        location !== `/${useMode.getState().name}/home` &&
        previewScreen && (
          <Suspense fallback={null}>
            <UserPrompts1 />
          </Suspense>
        )}
      {previewModelState && location === `/${useMode.getState().name}/home` && (
        <Suspense fallback={null}>
          <UserPrompts3D />
        </Suspense>
      )}
    </>
  )
}
