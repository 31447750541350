import { Html } from '@react-three/drei'
import { css, StyleSheet } from 'aphrodite'
import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
// import { useLocation } from 'wouter'
import { useTutorial } from '../../store'

function UserPrompts3D(props) {
  const [hintState, setHintState] = useState(false)
  // const [location, setLocation] = useLocation()

  useEffect(() => {
    setHintState(useTutorial.getState().showHint)
  }, [])

  useEffect(() => {
    const unsub = useTutorial.subscribe(
      (paw, previousPaw) => {
        setHintState(paw)
      },
      (state) => state.showHint
    )

    return function cleaup() {
      unsub()
    }
  }, [])

  if (hintState) {
    return (
      <>
        <group position={isMobile ? [5, 8, 5] : [5, 6.5, 5]}>
          <Html sprite center>
            <div className={css(styles.userPromptContainer)}>
              <div className={css(styles.userPromptContainer3)} />
              <div className={css(styles.TextContainer1)}>
                <div className={css(styles.dragText)}>
                  Click and drag the screen to rotate the model
                </div>
              </div>
            </div>
          </Html>
        </group>

        <group position={isMobile ? [-5, 8, -5] : [-5, 6.5, -5]}>
          <Html sprite center>
            <div className={css(styles.userPromptContainer)}>
              <div className={css(styles.userPromptContainer3)} />
              <div className={css(styles.TextContainer)}>
                <div className={css(styles.dragText)}>
                  Click the button to teleport into this room.
                </div>
              </div>
            </div>
          </Html>
        </group>
      </>
    )
  } else {
    return null
  }
}

export default UserPrompts3D

const horizontalMovement = {
  from: {
    left: 0,
  },
  to: {
    left: 100,
  },
}

const styles = StyleSheet.create({
  dragContainer: {
    position: 'absolute',
    animationName: [horizontalMovement],
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    '@media(min-width:556px) and (orientation:portrait)': {
      display: 'none',
    },
  },
  lottieSize: {
    width: 512 / 8,
    height: 783 / 8,
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1920px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 2560px)': {
      transform: 'scale(1.8)',
    },
  },
  handAnimation: {
    animationName: [horizontalMovement],
    animationDuration: '3s',
  },
  userPromptContainer: {
    width: 250,
    height: 'auto',
    textAlign: 'center',
    '@media(min-width:556px) and (orientation:portrait)': {
      display: 'none',
    },
    '@media (min-width: 556.98px) and (orientation: landscape)': {
      transform: 'scale(0.6)',
    },
    '@media (min-width: 992px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1200px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 1400px)': {
      transform: 'scale(1)',
    },
    '@media (min-width: 1600px)': {
      transform: 'scale(1.2)',
    },
    '@media (min-width: 1920px)': {
      transform: 'scale(1.5)',
    },
    '@media (min-width: 2560px)': {
      transform: 'scale(1.8)',
    },
  },
  userPromptContainer3: {
    backgroundColor: '#ffffffE6',
    borderRadius: '8px',
    textAlign: 'center',
    width: 242,
    height: 82,
  },
  TextContainer: {
    textAlign: 'center',
    position: 'absolute',
    top: 20,
    left: 30,
    width: '80%',
  },
  TextContainer1: {
    textAlign: 'center',
    position: 'absolute',
    top: 10,
    left: 30,
    width: '80%',
    '@media (min-width: 1400px)': {
      top: 20,
    },
    '@media (min-width: 1600px)': {
      top: 20,
    },
    '@media (min-width: 2560px)': {
      top: 20,
    },
  },
  dragText: {
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'left',
  },
})
