import React, { useState, useEffect } from 'react'
import { useMaster, useMaster3bhk } from '../../store'
import { css, StyleSheet } from 'aphrodite'
import MapMarker from '../assets/Hotspots/MapMarker'
import { useLocation } from 'wouter'
import { useSpring } from '@react-spring/core'
import { a as animated } from '@react-spring/web'
import MapImage from '../assets/images/MapImage.webp'
import MapImage3bhk from '../assets/images/Map3bhk.webp'
import { isMobileOnly } from 'react-device-detect'
function MapComponent({ showMap }) {
  const { getObject } = useMaster()
  const { getObject3bhk } = useMaster3bhk()
  const [location, setLocation] = useLocation()
  const [currentMode, setCurrentMode] = useState('2bhk')
  const [currentObject, setCurrentObject] = useState(null)

  const fadeinAnim = useSpring({
    // displayMap: showMap ? 1 : 0,
    opacity: showMap ? 1 : 0,
    config: {
      duration: 800,
    },
  })

  useEffect(() => {
    if (location.includes('3bhk')) {
      if (currentMode !== '3bhk') setCurrentMode('3bhk')
      setCurrentObject(getObject3bhk(location))
    } else {
      if (currentMode !== '2bhk') {
        setCurrentMode('2bhk')
        setCurrentObject(getObject(location))
      }
    }
  }, [location])

  const markerAnim = useSpring({
    opacity: 1,
    transform:
      currentObject != null && currentObject.mapMarkerPosition != null
        ? `translate(
                       ${
                         isMobileOnly
                           ? currentObject.mapMarkerPosition.mobileX
                           : currentObject.mapMarkerPosition.right
                       }vw, 
                       ${
                         isMobileOnly
                           ? currentObject.mapMarkerPosition.mobileY
                           : currentObject.mapMarkerPosition.bottom
                       }vh )`
        : `translate(0px, 0px)`,
    from: {
      opacity: 0,
    },
    config: {
      duration: 800,
    },
  })

  return (
    <>
      <animated.div className={css(styles.mapContainer)} style={fadeinAnim}>
        <div
          className={css(styles.backgroundContainer)}
          style={{
            width: '100%',
            height: '100%',
            backgroundImage:
              currentMode === '3bhk'
                ? `url(${MapImage3bhk})`
                : `url(${MapImage})`,
          }}
        >
          <animated.div className={css(styles.mapMarker)} style={markerAnim}>
            <MapMarker markerAnim={markerAnim} />
          </animated.div>
        </div>
      </animated.div>
    </>
  )
}

export default MapComponent

const styles = StyleSheet.create({
  mapContainer: {
    position: 'absolute',
    bottom: '14vh', //103
    right: '6vw', //94
    width: '30vw',
    height: '40vh',
    pointerEvents: 'none',
    zIndex: 10,
    // backgroundColor: 'red',
    backgroundPosition: 'center',

    '@media (max-width: 576px) and (orientation: landscape)': {
      // bottom: 38,
      // right: 54,
      width: '45vw',
      height: '65vh',
    },
    '@media (min-width: 768px) and (orientation: landscape)': {
      // bottom: 38,
      // right: 54,
      width: '45vw',
      height: '65vh',
    },
    '@media (min-width: 992px) and (orientation: landscape)': {
      // bottom: 56,
      // right: 48,
      width: '30vw',
      height: '40vh',
    },
    '@media (min-width: 1400px)': {
      // bottom: 95,
      // right: 76,
      width: '30vw',
      height: '40vh',
    },
    '@media (min-width: 2560px)': {
      // bottom: 115,
      // right: 76,
    },
  },
  backgroundContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  },
  mapMarker: {
    float: 'right',
    height: 'auto',
    width: '1.5vw',
    '@media (max-width: 576px) and (orientation: landscape)': {
      width: '2.5vw',
    },
    '@media (min-width: 768px) and (orientation: landscape)': {
      width: '2.5vw',
    },
    '@media (min-width: 992px) and (orientation: landscape)': {
      width: '2vw',
    },
    '@media (min-width: 1200px)': {
      width: '2vw',
    },
    '@media (min-width: 1400px)': {
      width: '1.5vw',
    },
    '@media (min-width: 2560px)': {
      width: '1.5vw',
    },
  },
})
