import React, { useState } from 'react'
// import { Route } from 'wouter'
import { useEverythingLoaded } from '../../store'
import AgreementScreen from './AgreementScreen'
// import LoadingScreenBranding from './LoadingScreenBranding'
import RotateScreen from './RotateScreen'
import SplashScreen from './SplashScreen'

export default function LoadingScreen() {
  const [done, setDone] = useState(false)
  const { setEverythingLoaded } = useEverythingLoaded()

  function LoadingCompleted() {
    setDone(true)
    setEverythingLoaded(true)
  }

  return (
    <>
      <RotateScreen />
      <SplashScreen loadingComplete={LoadingCompleted} />
      <AgreementScreen animDone={done} />
    </>
  )
}
