import React, { useEffect, useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { ApartmentInfoText } from '../../constants'
import {
  useApartmentPreview,
  useGuidedTour,
  useHotspotState,
  useMaster,
  useMaster3bhk,
  useTutorial,
} from '../../store'
import { fadeIn, fadeOut } from 'react-animations'
import Guide from '../assets/Buttons/Guide'
import UserPrompts from '../core/UserPrompts2D'
import { useLocation } from 'wouter'
import Map from '../assets/Buttons/Map'
import MapClose from '../assets/Buttons/MapClose'
import KeyPlan2bhk from '../assets/images/KeyPlan.webp'
import KeyPlan3bhk from '../assets/images/3BHKKeyplan.webp'
import { isMobile } from 'react-device-detect'

export default function ApartmentPreviewUI() {
  const [state, setState] = useState({
    componentVisibility: false,
    isFadeOut: true,
  })
  const { setGuidedTour } = useGuidedTour()
  const { setShowHint } = useTutorial()
  const { setApartmentPreview } = useApartmentPreview()
  const [location, setLocation] = useLocation()
  const [showMap, setShowMap] = useState(false)
  const { setHotspotVisibility } = useHotspotState()
  const [guideHover, setGuideHover] = useState(false)
  const [mapHover, setMapHover] = useState(false)
  const [currentObject, setCurrentObject] = useState(null)
  const { getObject } = useMaster()
  const { getObject3bhk } = useMaster3bhk()
  const [currentMode, setcurrentMode] = useState('2bhk')

  useEffect(() => {
    setState({
      ...state,
      componentVisibility: true,
      isFadeOut: false,
    })
  }, [])

  useEffect(() => {
    if (location.includes('3bhk')) {
      setCurrentObject(getObject3bhk(location))
      if (currentMode !== '3bhk') {
        setcurrentMode('3bhk')
      }
    } else {
      setCurrentObject(getObject(location))
      if (currentMode !== '2bhk') {
        setcurrentMode('2bhk')
      }
    }
  }, [location])

  function StartGuidedTour() {
    // setGuidedTour(true)
    setApartmentPreview(false)
    if (location.includes('3bhk')) {
      setLocation('/3bhk/livingroom1')
    } else {
      setLocation('/2bhk/livingroom1')
    }
  }

  function OnGuideButtonClicked() {
    setShowHint(true)
  }

  function showMapfunc() {
    console.log('Map Button Clicked')
    if (showMap) {
      setHotspotVisibility(true)
    } else {
      setHotspotVisibility(false)
    }
    setShowMap(!showMap)
  }

  function guideButtonState(bool) {
    setGuideHover(bool)
  }

  function mapButtonState(bool) {
    setMapHover(bool)
  }

  return (
    <>
      {/* //* Map Button and Map for desktop */}
      {!isMobile && (
        <div className={css(styles.keyPlan)}>
          <div
            style={{ opacity: 0 }}
            className={css(
              state.componentVisibility
                ? styles.textContainer1
                : styles.displayNone,
              state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn
            )}
          >
            {currentObject !== null && (
              <div className={css(styles.headerText)}>
                {currentObject.title}
              </div>
            )}
            {currentObject !== null && (
              <div className={css(styles.subHeaderText)}>
                {currentObject.tower}
              </div>
            )}
          </div>
          <div className={css(styles.keyplanContainerDesktop)}>
            <div>
              <button
                className={css(styles.mapButtonContainer)}
                onClick={showMapfunc}
                onMouseEnter={() => {
                  mapButtonState(true)
                }}
                onMouseLeave={() => {
                  mapButtonState(false)
                }}
              >
                {!showMap ? (
                  <Map opacity={mapHover ? 1 : 0.75} />
                ) : (
                  <MapClose opacity={mapHover ? 1 : 0.75} />
                )}
              </button>
            </div>
            <div
              className={css(
                showMap ? styles.mapContainer : styles.displayNone
              )}
            >
              <img
                alt="Key Plan 2BHK"
                src={currentMode === '3bhk' ? KeyPlan3bhk : KeyPlan2bhk}
                className={css(styles.mapSize)}
              />
            </div>
          </div>
        </div>
      )}
      {/* //* Map Button and Map for Mobile */}
      {isMobile && (
        <>
          <button
            className={css(styles.mapButtonContainer)}
            onClick={showMapfunc}
            onMouseEnter={() => {
              mapButtonState(true)
            }}
            onMouseLeave={() => {
              mapButtonState(false)
            }}
          >
            {!showMap ? (
              <Map opacity={mapHover ? 1 : 0.75} />
            ) : (
              <MapClose opacity={mapHover ? 1 : 0.75} />
            )}
          </button>
          <div
            style={{ opacity: 0 }}
            className={css(
              state.componentVisibility
                ? styles.textContainerMobile
                : styles.displayNone,
              state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn
            )}
          >
            {currentObject !== null && (
              <div className={css(styles.headerText)}>
                {currentObject.title}
              </div>
            )}
            {currentObject !== null && (
              <div className={css(styles.subHeaderText)}>
                {currentObject.tower}
              </div>
            )}
          </div>
          <div
            className={css(
              showMap ? styles.keyplanContainer : styles.displayNone
            )}
          >
            <div
              className={css(
                showMap ? styles.mapContainerMobile : styles.displayNone
              )}
            >
              <img
                alt="Key Plan 2BHK"
                src={currentMode === '3bhk' ? KeyPlan3bhk : KeyPlan2bhk}
                className={css(styles.mapSize)}
              />
            </div>
          </div>
        </>
      )}
      {/* //* Start The Guided Tour Button */}
      <button
        onClick={StartGuidedTour}
        style={{ opacity: 0 }}
        className={css(
          state.componentVisibility
            ? styles.buttonContainer
            : styles.displayNone,
          state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn
        )}
      >
        {ApartmentInfoText.tour}
      </button>
      {/* //* Show the hints Button*/}
      <button
        onMouseEnter={() => {
          guideButtonState(true)
        }}
        onMouseLeave={() => {
          guideButtonState(false)
        }}
        style={{ opacity: 0 }}
        className={css(
          state.componentVisibility
            ? styles.guideContainer
            : styles.displayNone,
          state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn
        )}
        onClick={OnGuideButtonClicked}
      >
        <Guide opacity={guideHover ? 1 : 0.75} />
      </button>
      <UserPrompts />
      {/* <button className={css(state.componentVisibility ? styles.planViewContainer: styles.displayNone, state.isFadeOut ? styles.screenfadeOut : styles.screenfadeIn)} onClick={() => {planView()}} >
              <PlanView/>
          </button> */}
    </>
  )
}

const styles = StyleSheet.create({
  // textContainer: {
  //   position: 'absolute',
  //   top: 41,
  //   left: '50%',
  //   transform: 'translateX(-50%)',
  //   textAlign: 'center',
  //   '@media(min-width:556px) and (orientation:landscape)': {
  //     top: 27,
  //   },
  //   '@media(min-width:992px) and (orientation:landscape)': {
  //     top: 41,
  //   },
  //   '@media(min-width:1200px)': {
  //     top: 41,
  //   },
  // },
  keyPlan: {
    position: 'absolute',
    zIndex: 100,
    // animationName:[fadeIn],
    // animationDuration:'0.5s',
    top: 41,
    right: 15,
    textAlign: 'right',
    '@media(min-width:556px) and (orientation:landscape)': {
      top: 8,
      right: 15,
    },
    '@media(min-width:768px) and (orientation:landscape)': {
      top: 8,
      right: 15,
    },
    '@media(min-width:992px) and (orientation:landscape)': {
      top: 8,
      right: 15,
    },
    '@media(min-width:1200px)': {
      top: 41,
      right: 39,
    },
  },
  // keyPlanMobile: {
  //   position: 'absolute',
  //   zIndex: 100,
  //   top: 0,
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  //   width: '100%',
  //   height: '100%',
  // },
  textContainer1: {},
  textContainerMobile: {
    position: 'absolute',
    top: '2vh',
    right: '2vw',
    textAlign: 'right',
  },
  headerText: {
    fontFamily: 'DIN-Bold',
    color: '#E5BD79',
    fontSize: 48,
    lineHeight: '58px',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 16,
      lineHeight: '19px',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 38,
      lineHeight: '48px',
    },
    '@media(min-width: 1200px)': {
      fontSize: 42,
      lineHeight: '54px',
    },
    '@media(min-width: 1400px)': {
      fontSize: 48,
      lineHeight: '58px',
    },
    '@media(min-width: 1600px)': {
      fontSize: 48,
      lineHeight: '58px',
    },
    '@media(min-width: 2560px)': {
      fontSize: 56,
      lineHeight: '65px',
    },
  },
  subHeaderText: {
    fontFamily: 'DIN-Regular',
    color: '#E5BD79',
    fontSize: 48,
    lineHeight: '58px',
    '@media(min-width:556px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      fontSize: 12,
      lineHeight: '14px',
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      fontSize: 28,
      lineHeight: '32px',
    },
    '@media(min-width: 1200px)': {
      fontSize: 32,
      lineHeight: '36px',
    },
    '@media(min-width: 1400px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 1600px)': {
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 2560px)': {
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  buttonContainer: {
    width: 307,
    height: 68,
    backgroundColor: '#ffffffBF',
    position: 'absolute',
    bottom: 46,
    left: '50%',
    transform: 'translateX(-50%)',
    border: '1px solid #ffffffE6',
    fontFamily: 'DIN-Regular',
    color: '#850C70',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#ffffffEC',
    },
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 173,
      height: 31,
      fontSize: 12,
      lineHeight: '14.06px',
      bottom: 18,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      width: 173,
      height: 31,
      fontSize: 12,
      lineHeight: '14.06px',
      bottom: 18,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 307,
      height: 68,
      fontSize: 22,
      lineHeight: '28px',
      bottom: 46,
    },
    '@media(min-width: 1200px)': {
      width: 307,
      height: 68,
      fontSize: 22,
      lineHeight: '28px',
    },
    '@media(min-width: 1400px)': {
      width: 240,
      height: 53.16,
      fontSize: 16,
      lineHeight: '18.75px',
      bottom: 53.84,
    },
    '@media(min-width: 1600px)': {
      width: 307,
      height: 68,
      fontSize: 24,
      lineHeight: '28px',
    },
    '@media(min-width: 2560px)': {
      width: 407,
      height: 88,
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  // planViewContainer: {
  //   width: 48,
  //   height: 48,
  //   position: 'absolute',
  //   bottom: 78,
  //   left: 96,
  //   background: 'none',
  //   border: 'none',
  //   outline: 0,
  //   padding: 0,
  //   '@media(min-width:556px) and (orientation:landscape)': {
  //     width: 48,
  //     height: 48,
  //     bottom: 78,
  //     left: 96,
  //   },
  //   '@media(min-width: 768px) and (orientation:landscape)': {
  //     width: 48,
  //     height: 48,
  //     bottom: 78,
  //     left: 96,
  //   },
  //   '@media(min-width: 992px) and (orientation:landscape)': {
  //     width: 48,
  //     height: 48,
  //     bottom: 78,
  //     left: 96,
  //   },
  //   '@media(min-width: 1200px)': {
  //     width: 48,
  //     height: 48,
  //     bottom: 78,
  //     left: 96,
  //   },
  //   '@media(min-width: 1400px)': {
  //     width: 48,
  //     height: 48,
  //     bottom: 78,
  //     left: 96,
  //   },
  //   '@media(min-width: 1600px)': {
  //     width: 48,
  //     height: 48,
  //     bottom: 78,
  //     left: 96,
  //   },
  // },
  guideContainer: {
    width: 48,
    height: 48,
    position: 'absolute',
    bottom: 56,
    left: 96,
    background: 'none',
    border: 'none',
    outline: 0,
    padding: 0,
    ':hover': {
      cursor: 'pointer',
    },
    '@media(min-width:556px) and (orientation:landscape)': {
      width: 32,
      height: 32,
      bottom: 18,
      left: 15,
    },
    '@media(min-width: 768px) and (orientation:landscape)': {
      width: 38,
      height: 38,
      bottom: 18,
      left: 15,
    },
    '@media(min-width: 992px) and (orientation:landscape)': {
      width: 48,
      height: 48,
      bottom: 56,
      left: 32,
    },
    '@media(min-width: 1200px)': {
      width: 48,
      height: 48,
      bottom: 56,
      left: 32,
    },
    '@media(min-width: 1400px)': {
      width: 48,
      height: 48,
      bottom: 53.84,
      left: 32,
    },
    '@media(min-width: 1600px)': {
      width: 48,
      height: 48,
      bottom: 56,
      left: 32,
    },
    '@media(min-width: 2560px)': {
      width: 64,
      height: 64,
      bottom: 56,
      left: 32,
    },
  },
  displayNone: {
    display: 'none',
    pointerEvents: 'none',
  },
  screenfadeIn: {
    animationName: {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '-moz-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Firefox */,
    '-webkit-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Safari and Chrome */,
    '-o-animation': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    } /* Opera */,

    animationFillMode: 'forwards',
    '-webkit-animation-fill-mode': 'forwards',
    animationDuration: '1s',
    '-moz-animation-duration': '1s' /* Firefox */,
    '-webkit-animation-duration': '1s' /* Safari and Chrome */,
    '-o-animation-duration': '1s' /* Opera */,
    animationDelay: '0.5s',
    '-webkit-animation-delay': '0.5s' /* Safari and Chrome */,
  },
  screenfadeOut: {
    animationName: fadeOut,
    animationDuration: '1s',
  },
  mapButtonContainer: {
    position: 'absolute',
    width: 48,
    height: 48,
    padding: 0,
    outline: 'none',
    background: 'none',
    border: 'none',
    pointerEvents: 'auto',
    zIndex: 100,
    bottom: '2.5vh',
    right: '1.2vw',

    ':hover': {
      cursor: 'pointer',
      opacity: 1,
    },
    '@media(min-width:576px) and (orientation: landscape)': {
      transform: 'scale(0.7)',
      top: 'unset',
      bottom: '2.5vh',
      right: '1.2vw',
    },
    '@media(min-width: 768px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      top: 'unset',
      bottom: '2.5vh',
      right: '1.2vw',
    },
    '@media(min-width: 992px) and (orientation: landscape)': {
      transform: 'scale(0.8)',
      top: 'unset',
      bottom: '2.5vh',
      right: '1.2vw',
    },
    '@media(min-width: 1200px)': {
      transform: 'scale(0.8)',
      top: '2vh',
    },
    '@media(min-width: 1400px)': {
      transform: 'scale(1)',
      top: '2vh',
    },
    '@media(min-width: 2560px)': {
      transform: 'scale(1.5)',
      top: '2vh',
    },
  },
  keyplanContainer: {
    position: 'absolute',
    backgroundColor: '#850C70E5',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 99,
    textAlign: 'center',
    animationName: [fadeIn],
    animationDuration: '0.5s',
  },
  mapContainer: {
    marginTop: '-5vh',
    marginRight: '-2vh',
  },
  mapContainerMobile: {
    marginTop: '5vh',
  },
  keyplanContainerDesktop: {},
  mapSize: {
    width: 271,
    height: 260,
  },
})
