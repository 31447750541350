import React, { useEffect } from 'react'
import { useLocation } from 'wouter'
import { useEverythingLoaded, usePanoramaViewer } from '../../store'
import GuidedTour from '../context/GuidedTour'
import { PanoramaPreLoader } from './PanoramaPreLoader'
import PanoramaViewer from './PanoramaViewer'

export default function PanoramaManager() {
  const { everythingLoaded } = useEverythingLoaded()
  const [location] = useLocation()
  const { currentLocation, allLocations } = usePanoramaViewer()

  return (
    <>
      <GuidedTour />
      <PanoramaPreLoader />
      <PanoramaViewer
        argument={everythingLoaded ? location : currentLocation}
      />
    </>
  )
}
