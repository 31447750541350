import React from 'react'

function InfoHotspotCross(props) {
  return (
    <svg viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6069 0.0820808L17.4255 0.160843L16.149 0.406963L14.8724 0.653088L13.6385 1.06001C12.4276 1.46037 12.3849 1.47678 11.2889 1.99857C10.2092 2.51378 10.137 2.55644 9.07376 3.24231C8.00722 3.93145 7.94487 3.9774 7.00632 4.77812C6.07433 5.569 6.00541 5.63463 5.185 6.54365C4.35475 7.46251 4.31208 7.5183 3.60325 8.55201L2.87144 9.61527L2.25121 10.8525L1.6277 12.0896L1.18467 13.3859L0.744934 14.6821L0.462712 16.1261L0.180491 17.57L0.0918861 19.0303L0 20.4907L0.0590697 21.4751C0.114858 22.4006 0.131266 22.522 0.315038 23.5229C0.488965 24.4713 0.544753 24.7043 0.823693 25.6396C2.83534 32.3702 8.55525 39.8557 18.3116 48.5258C19.2206 49.3331 20.5694 49.402 21.5145 48.6866C21.9116 48.3879 24.655 45.8512 25.9381 44.5944C32.2192 38.4511 36.295 32.974 38.3821 27.8744C38.7694 26.9325 38.8908 26.588 39.1665 25.6757C39.4585 24.6912 39.5078 24.4877 39.6882 23.5098C39.872 22.4892 39.8884 22.371 39.9409 21.4587L40 20.4907L39.9081 18.9975L39.8129 17.5044L39.5242 16.0604L39.2387 14.6165L38.7956 13.3367L38.3559 12.0568L37.7455 10.8426L37.1384 9.6284L36.4066 8.55858C35.6912 7.52158 35.6485 7.46579 34.8183 6.54693C33.9913 5.63135 33.9355 5.57556 32.9806 4.76499C32.0256 3.95771 31.9632 3.90848 30.9131 3.23246C29.8696 2.55644 29.7908 2.51378 28.7144 1.99857C27.6216 1.47678 27.5757 1.46037 26.3615 1.06001L25.121 0.649807L23.8641 0.406963L22.6073 0.164124L21.2946 0.0820808C20.5759 0.0361366 19.9426 4.19617e-05 19.8868 4.19617e-05C19.8343 0.0033226 19.2567 0.0394211 18.6069 0.0820808Z"
        fill="#850C70"
        fillOpacity={props.opacity}
      />
      <path
        d="M22.0189 22.5007L27.0643 17.4539C27.2006 17.3222 27.3093 17.1647 27.3841 16.9906C27.4589 16.8165 27.4983 16.6292 27.4999 16.4397C27.5016 16.2502 27.4655 16.0622 27.3937 15.8868C27.322 15.7114 27.216 15.552 27.082 15.418C26.948 15.284 26.7886 15.178 26.6132 15.1063C26.4378 15.0345 26.2498 14.9984 26.0603 15.0001C25.8708 15.0017 25.6835 15.0411 25.5094 15.1159C25.3353 15.1907 25.1778 15.2994 25.0461 15.4357L19.9993 20.4811L14.9539 15.4357C14.8222 15.2994 14.6647 15.1907 14.4906 15.1159C14.3165 15.0411 14.1292 15.0017 13.9397 15.0001C13.7502 14.9984 13.5622 15.0345 13.3868 15.1063C13.2114 15.178 13.052 15.284 12.918 15.418C12.784 15.552 12.678 15.7114 12.6063 15.8868C12.5345 16.0622 12.4984 16.2502 12.5001 16.4397C12.5017 16.6292 12.5411 16.8165 12.6159 16.9906C12.6907 17.1647 12.7994 17.3222 12.9357 17.4539L17.9811 22.4993L12.9357 27.5461C12.7994 27.6778 12.6907 27.8353 12.6159 28.0094C12.5411 28.1835 12.5017 28.3708 12.5001 28.5603C12.4984 28.7498 12.5345 28.9378 12.6063 29.1132C12.678 29.2886 12.784 29.448 12.918 29.582C13.052 29.716 13.2114 29.822 13.3868 29.8937C13.5622 29.9655 13.7502 30.0016 13.9397 29.9999C14.1292 29.9983 14.3165 29.9589 14.4906 29.8841C14.6647 29.8093 14.8222 29.7006 14.9539 29.5643L19.9993 24.5189L25.0461 29.5643C25.1778 29.7006 25.3353 29.8093 25.5094 29.8841C25.6835 29.9589 25.8708 29.9983 26.0603 29.9999C26.2498 30.0016 26.4378 29.9655 26.6132 29.8937C26.7886 29.822 26.948 29.716 27.082 29.582C27.216 29.448 27.322 29.2886 27.3937 29.1132C27.4655 28.9378 27.5016 28.7498 27.4999 28.5603C27.4983 28.3708 27.4589 28.1835 27.3841 28.0094C27.3093 27.8353 27.2006 27.6778 27.0643 27.5461L22.0189 22.4993V22.5007Z"
        fill="white"
      />
    </svg>
  )
}

export default InfoHotspotCross
