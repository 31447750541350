import { Html } from '@react-three/drei';
import { useControls } from 'leva';
import React from 'react';
import HotspotSpawn from '../core/HotspotSpawn';
import Hotspot from '../core/Hotspot';
import BathroomHS from '../assets/Hotspots/BathroomHS';
import { StyleSheet,css } from 'aphrodite';
import InfoHS from '../assets/Hotspots/InfoHS';
import Arrow from '../assets/Hotspots/Arrow';
import FreeRoam from '../assets/Hotspots/FreeRoam';



export default function Controllables(props){

    const {position} = useControls("Hotspot",{
        position:{
            value: { x: 0, y: 0,z:0 },
            step: 0.01,
        },
    })
    const {positionI,widthI, heightI} = useControls("Info Hotspot",{
        positionI:{
            value: { x: 0, y: 0,z:0 },
            step: 1,
        },
        widthI:{
            value: 40,
            step:1
        },
        heightI:{
            value: 50,
            step:1
        }
    })
    const {positionA,scaleA,rotationA} = useControls("Arrow Hotspot",{
        positionA:{
            value: { x: 0, y: 0,z:0 },
            step: 1,
        },
        scaleA:{
            value: [0.1,0.1,0.1],
            step: 0.2,
        },
        rotationA:{
            value : {x: 0 , y: 0 , z: 0},
            step: 0.2,
        }
    })

    const {positionF,scaleF,rotationF} = useControls("Free Roam Hotspot",{
        positionF:{
            value: { x: 0, y: 0,z:0 },
            step: 1,
        },
        scaleF:{
            value: [0.1,0.1,0.1],
            step: 0.2,
        },
        rotationF:{
            value : {x: 0 , y: 0 , z: 0},
            step: 0.2,
        }
    })

    return(
        <>
            <Hotspot position={[position.x,position.y,position.z]} hidden={true}>
                <HotspotSpawn svg={<BathroomHS/> } />
            </Hotspot>
            <Hotspot position={[positionI.x,positionI.y,positionI.z]} type='Info' hidden={true}>
                <button className={css(styles.hospotButton)} style={{width:widthI, height:heightI}}>
                    <InfoHS/>
                </button>
            </Hotspot>
            <Hotspot position={[positionF.x,positionF.y,positionF.z]} scale={scaleF} type='FreeRoam' hidden={true}>
                <button className={css(styles.hospotButton)} >
                    <FreeRoam/>
                </button>
            </Hotspot>
            <Hotspot position={[positionA.x,positionA.y,positionA.z]} scale={scaleA} rotation={[rotationA.x, rotationA.y, rotationA.z]} type='Arrow' hidden={true}>
                <button className={css(styles.hospotButton)} >
                    <Arrow/>
                </button>
            </Hotspot>
        </>
    )
}

const styles = StyleSheet.create({
    hospotButton:{
        outline:0,
        background:'none',
        border:'none',
      },
})